import React from "react";
import { Box, Text, Flex } from "rebass";
import SearchResult from "./SearchResult";
import { useTranslation } from "react-i18next";

export default function SearchResultsCard(props) {
  const {
    schoolGeometries,
    setActiveSchoolId,
    setAnimateMarker,
    searchInfo,
    nearbySchools
  } = props;
  const { t } = useTranslation();

  const mapCategories = geometries => {
    return geometries.reduce(
      ({ neighborhood, other }, school) => {
        if (nearbySchools.includes(school)) {
          neighborhood.push(school);
        } else {
          other.push(school);
        }
        return { neighborhood, other };
      },
      {
        neighborhood: [],
        other: []
      }
    );
  };

  const mapResults = geometries => {
    return geometries.map(school => school && (
      <SearchResult
        key={school.id}
        activeSchool={school}
        setActiveSchoolId={setActiveSchoolId}
        setAnimateMarker={setAnimateMarker}
      />
    ));
  };

  const createSearchResults = geometries => {
    if (!schoolGeometries || schoolGeometries.length < 1) {
      return (
        <Text p={3} variant="body">
          {t("no_results_found")}
        </Text>
      );
    } else {
      if (searchInfo.lat) {
        const searchGeometries = mapCategories(schoolGeometries);
        return breakItUp(searchGeometries);
      } else {
        return mapResults(schoolGeometries);
      }
    }
  };

  const breakItUp = geometries => {
    return (
      <React.Fragment>
        <Flex
          pr={2}
          pt={2}
          pb={1}
          pl={4}
          alignItems="center"
          sx={{
            borderBottom: "1px solid",
            borderColor: "black20",
            display: "inline-block"
          }}
        >
          <Text
            variant="body"
            sx={{
              fontSize: "18px !important"
            }}
            id="your-neighborhood-schools"
          >
            {t("your_neighborhood_schools")}
          </Text>
        </Flex>
        {mapResults(geometries.neighborhood)}
        <Flex
          pr={2}
          pt={2}
          pb={1}
          pl={4}
          mt={3}
          alignItems="center"
          sx={{
            borderBottom: "1px solid",
            borderColor: "black20",
            display: "inline-block"
          }}
        >
          <Text
            variant="body"
            sx={{
              fontSize: "18px !important"
            }}
          >
            {t("your_application_schools")}
          </Text>
        </Flex>
        {mapResults(geometries.other)}
      </React.Fragment>
    );
  };

  return (
    <Box
      className="search-results-list"
      flex={1}
      sx={{
        overflowX: "hidden",
        overflowY: "scroll"
      }}
    >
      {createSearchResults(schoolGeometries)}
    </Box>
  );
}
