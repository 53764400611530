export const types = {
  TOGGLE_MAGNET: "TOGGLE_MAGNET",
  TOGGLE_CHARTER: "TOGGLE_CHARTER",
  TOGGLE_NEIGHBORHOOD: "TOGGLE_NEIGHBORHOOD",
  TOGGLE_ALTERNATIVE: "TOGGLE_ALTERNATIVE",
  TOGGLE_ALL: "TOGGLE_ALL"
};

export const schoolTypeInitialToggleState = {
  removeNeighborhood: false,
  removeMagnet: false,
  removeCharter: false,
  removeAlternative: false,
};

export const schoolTypeReducer = (state = schoolTypeInitialToggleState, action) => {
  switch (action.type) {
    case types.TOGGLE_NEIGHBORHOOD:
      return {
        ...state,
        removeNeighborhood: !state.removeNeighborhood
      };
    case types.TOGGLE_MAGNET:
      return {
        ...state,
        removeMagnet: !state.removeMagnet
      };
    case types.TOGGLE_CHARTER:
      return {
        ...state,
        removeCharter: !state.removeCharter
      };
    case types.TOGGLE_ALTERNATIVE:
      return {
        ...state,
        removeAlternative: !state.removeAlternative
      };
    case types.TOGGLE_ALL:
      return schoolTypeInitialToggleState;
    default:
      return state;
  }
};
