import React from "react";
import {Box} from "rebass";
import {Map, Marker} from "google-maps-react";
import {selectSchoolIcon} from "utils/map-helpers";

export default function SchoolMap(props) {
  const {schoolInfo} = props;

  const position = {
    lat: schoolInfo.school_geometry.lat,
    lng: schoolInfo.school_geometry.lng
  };

  return (<Box mt={4} height={12} sx={{
      position: "relative",
      display: [
        'block', 'none'
      ],
      borderRadius: 'default',
      overflow: 'hidden'
    }}>
    <Map google={window.google} initialCenter={position} disableDefaultUI={true} gestureHandling={"none"} zoom={14} zoomControl={true}>
      <Marker icon={{
          url: selectSchoolIcon(schoolInfo.category, schoolInfo.is_early_childhood)
        }} position={position}/>
    </Map>
  </Box>);
}
