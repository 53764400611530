import React from "react";
import {Box, Flex, Text} from "rebass";
import {determineSchoolMetaTags} from "utils/school-helpers";

export default function MetaTags(props) {
  const {school, language} = props;
  return (<Flex flexDirection="row" justifyContent="flex-start">
    <Text variant="thickSmall" color="black40" sx={{
        marginLeft: "21px"
      }}>
      {
        determineSchoolMetaTags(school, language).map((tag, index) => [<Box key={index} sx={{
            color: tag[1],
            textDecorationLine: tag[2],
            display: "inline-block"
          }}>
          {
            (
              index
              ? ", "
              : "") + tag[0]
          }
        </Box>
          ])
      }
    </Text>
  </Flex>);
}
