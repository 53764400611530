import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import api from "api";
import { keyReducer } from "utils";
import theSlugify from "slugify";

export const slugify = (string, options = {lower: true, strict: true}) => {
  return theSlugify(string, options)
}

export const SchoolDataContext = React.createContext();

export const SchoolDataProvider = (props) => {
  const [activities, setActivities] = useState({});
  const [schools, setSchools] = useState({});
  const [polygons, setPolygons] = useState({});
  const [districtPolygon, setDistrictPolygon] = useState(null);

  const {data: activityData} = useQuery("activities", async () => {
    const response = await api.activities.get();
    return response.data.data.reduce(keyReducer("id"), {});
  });

  const {data: schoolData} = useQuery("schools", async () => {
    const response = await api.schools.get();
    return response.data.data.reduce(keyReducer("id"), {});
  });

  const {data: polygonsData} = useQuery("polygons", async () => {
    const response = await api.polygons.get();
    return response.data.data.reduce(keyReducer("school_id"), {});
  });

  const schoolSlugToId = (slug, fallback) => {
    const school = Object.values(schools).find(school => slug === slugify(school.name))

    if (school) {
      return school.id
    }

    if (typeof fallback === 'undefined') {
      throw new Error(`School by slug not found: ${slug}`)
    }

    return fallback
  }

  const schoolIdToSlug = (id, fallback) => {
    const school = schools[id]

    if (school) {
      return slugify(school.name)
    }

    if (typeof fallback === 'undefined') {
      throw new Error(`School by ID not found: ${id}`)
    }

    return fallback
  }

  useEffect(() => {
    if (activityData && schoolData) {
      setActivities(activityData);
      setSchools(schoolData);
    }
  }, [activityData, schoolData]);

  useEffect(() => {
    if (polygonsData) {
      setDistrictPolygon(polygonsData[0])
      setPolygons(polygonsData)
    }
  }, [polygonsData]);

  return (
    <SchoolDataContext.Provider value={{
      activities,
      schools,
      polygons,
      districtPolygon,
      schoolIdToSlug,
      schoolSlugToId
    }}>
      {props.children}
    </SchoolDataContext.Provider>
  );
};

export const withSchoolData = (Component) => {
  return (props) => (
    <SchoolDataContext.Consumer>
      {(value) => <Component schoolsData={value} {...props} />}
    </SchoolDataContext.Consumer>
  );
};

export default SchoolDataContext;
