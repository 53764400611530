import React from "react";
import {Box} from "rebass";

const HorizontalRule = props => (<Box {...props} sx={{
    width: "30%",
    height: "2px",
    backgroundColor: "black5"
  }}/>);

export default HorizontalRule
