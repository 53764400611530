import PickList from "utils/pick-list";
import { schoolPickerList } from "api/storage";

export const schoolPickerReducer = (state, action) => {
  switch (action.type) {
    case "init": {
      const list = new PickList(schoolPickerList.get(), state && state.limit);
      schoolPickerList.set(list);
      return { ...state, list };
    }

    case "clear": {
      const list = state.list;
      list.clear();
      schoolPickerList.set(list);
      return { ...state, list };
    }

    case "add": {
      const list = state.list;
      list.add(action.payload);
      schoolPickerList.set(list);
      return { ...state, list };
    }

    case "remove": {
      const list = state.list;
      list.delete(action.payload);
      schoolPickerList.set(list);
      return { ...state, list };
    }

    case "replace": {
      const list = state.list;
      list.replace(action.payload);
      schoolPickerList.set(list);
      return { ...state, list };
    }

    default: {
      throw new Error(`Invalid action type: "${action.type}"`);
    }
  }
};

export default schoolPickerReducer;
