import React from "react";
import MultiSelectCheckboxes from "react-multiselect-checkboxes";
import {Box, Button} from "rebass";
import makeAnimated from "react-select/animated";
import $ from "jquery";
import {components} from "react-select";
import {Theme} from "themes/Theme";
import {useTranslation} from "react-i18next";
import i18n from "i18n";
/**
 * Custom Dropdown Button Label provides a wrapper of the default getDropdownButtonLabel prop exposed
 * from ReactMultiselectCheckboxes.
 *
 * @param {function} multiSelectedButtonLabel Function to format the button label when multiple are selected.
 */
const customDropdownButtonLabel = multiSelectedButtonLabel => {
  /**
   * Original getDropdownButtonLabel prop function lifted from ReactMultiselectCheckboxes with
   * multi-value select returning value passed to provided `multiSelectedButtonLabel` argument.
   *
   * @param {*} param0 Parameters for overridden getDropdownButtonLabel prop.
   */
  const getDropdownButtonLabel = ({placeholderButtonLabel, value}) => {
    if (!value) {
      return placeholderButtonLabel;
    }
    /**
      If no options are selected, return placeholderButtonLabel.
      If multiple options are selected, return multiSelectedButtonLabel.
      If a single school type is selected (value is a string), translate the value.
      If a single activity is selected (value is an integer), return the label. TODO: Update this once Spanish translations are available…
    */
    if (Array.isArray(value)) {
      if (value.length === 0) {
        return placeholderButtonLabel;
      } else if (value.length > 1) {
        return multiSelectedButtonLabel(value.length);
      } else {
        return isNaN(value[0].value)
          ? i18n.t(value[0].value)
          : value[0].label;
      }
    }
    return value.label;
  };

  return getDropdownButtonLabel;
};

export {
  customDropdownButtonLabel
};

export default function MultiSelect(props) {
  const handleDoneClick = props => {
    const clickedButton = props.target;
    $(clickedButton).closest("div:has(button[type='button'])").children("button").click();
  };
  const {t} = useTranslation();

  const mySelectContainer = props => {
    return (<components.SelectContainer {...props}>
      {props.children}
      <Box sx={{
          p: 2,
          backgroundSize: "cover",
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
          backgroundColor: "black5",
          position: "relative"
        }}>
        <Button variant="full" onClick={handleDoneClick}>
          {t("done")}
        </Button>
      </Box>
    </components.SelectContainer>);
  };

  const myOption = props => {
    if (["elementary", "middle", "high", "early_childhood"].includes(props.data.value)) {
      return (<components.Option {...props}>
        <Box mr={2} sx={{
            verticalAlign: "middle",
            display: "inline-flex"
          }}>
          <svg width="20" height="20" viewBox="0 0 72 90" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="36" cy="35" r="26" stroke={Theme.colors["black40"]} strokeWidth="5" fill={Theme.colors[props.data.value]}/>
          </svg>
        </Box>
        {props.children}
      </components.Option>);
    } else {
      return <components.Option {...props}>{props.children}</components.Option>;
    }
  };

  const containerComponents = () => {
    let components = makeAnimated();
    components.SelectContainer = mySelectContainer;
    components.Option = myOption;
    return components;
  };

  return (
    <MultiSelectCheckboxes
      {...props}
      className="react-select-container"
      classNamePrefix="react-select"
      components={containerComponents()}
      theme={theme => ({
        ...theme,
        baseUnit: `${Theme.sizes[1] + "px"}`,
        menuGutter: `${Theme.sizes[2] + "px"}`,
        borderRadius: `${Theme.radii.default + "px"}`,
        colors: {
          ...theme.colors,
          primary: `${Theme.colors.primary}`,
          primary75: `${Theme.colors.primary75}`,
          primary50: `${Theme.colors.primary50}`,
          primary25: `${Theme.colors.primary25}`
        }
      })}
      styles={{
        option: (styles, state) => ({
          ...styles,
          borderTop: `.05px solid ${Theme.colors.white}`,
          cursor: "pointer",
          color: state.isSelected
            ? `${Theme.colors.white}`
            : `${Theme.colors.black100}`,
          backgroundColor: state.isSelected
            ? `${Theme.colors.primary}`
            : `${Theme.colors.white}`,
          fontWeight: "400 !important",
          "input[type='checkbox']": {
            position: "absolute",
            opacity: 0
          } // hides checkboxes
        }),
        container: styles => ({
          ...styles,
          paddingTop: "1px", marginTop: "-1px", // NOTE: prevent margin collapsing with child control
          boxShadow: `${Theme.shadows.menu} !important`,
          borderRadius: `${Theme.radii.default + "px"} !important`,
          fontFamily: `${Theme.fonts.body} !important`,
          fontSize: `${Theme.fontSizes[3] + "px"} !important`
        }),
        dropdownButton: (styles, state) => ({
          ...styles,
          fontFamily: `${Theme.fonts.body} !important`,
          fontSize: `${Theme.fontSizes[3] + "px"} !important`,
          borderRadius: `${Theme.radii.default + "px"} !important`,
          height: `${Theme.sizes[6] + "px"} !important`,
          paddingRight: `${Theme.sizes[3] + "px"} !important`,
          paddingLeft: `${Theme.sizes[3] + "px"} !important`,
          backgroundColor: state.value !== 0
            ? `${Theme.colors.transparent} !important`
            : `${Theme.colors.black100} !important`,
          border: state.value !== 0
            ? `solid 3px ${Theme.colors.primary} !important`
            : `solid 1px ${Theme.colors.primary} !important`,
          paddingTop: state.value !== 0
            ? `5px`
            : null,
          boxSizing: "border-box !important",
          color: `${Theme.colors.white} !important`
        }),
        groupHeading: styles => ({
          ...styles,
          marginTop: "-4px", // NOTE: hack …MenuList which has padding-top: 4px
          borderBottom: `.5px solid lightgrey`,
          backgroundColor: `${Theme.colors.black5}`,
          ":hover": null
        }),
        dropdownIndicator: styles => ({
          ...styles,
          display: "none"
        }),
        control: props.styles.control
        // unique component to react-multiselect-checkboxes, vs react-select parent
      }}
    />
  );
}

MultiSelect.propTypes = MultiSelectCheckboxes.propTypes;

MultiSelect.defaultProps = MultiSelectCheckboxes.defaultProps;
