import React from "react";
import {Box} from "rebass";
import {whiteExitButton} from "icons";

const Tag = props => (<Box {...props} variant="clear" bg="primary" color="white" mr={2} pl={0} pr={2} sx={{
    // the "x" icon
    backgroundImage: `url('${whiteExitButton}')`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right 8px center",
    padding: 1,
    backgroundSize: 9,
    flexShrink: "0",
    display: "flex",
    alignItems: "center",
    fontSize: 1,
    borderRadius: "4px",
    cursor: "pointer",
    fontFamily: "body",
    fontWeight: "semiBold",
    letterSpacing: "0.8px",
    textTransform: "uppercase"
  }}>
  <Box display="inline-block" pl={2} pr={3}>
    {props.children}
  </Box>
</Box>);

export default Tag
