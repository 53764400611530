export function getMetaContent(name) {
  const element = document.querySelector(`meta[name="${name}"]`)
  if (element) {
    return element.getAttribute('content') || ''
  } else {
    return ''
  }
}

export const RANDOM_BUILD_KEY = getMetaContent('x-env:random_build_key')
