import React, {useContext} from "react";
import {Flex, Box} from "rebass";
import {Input} from "@rebass/forms";
import {Theme} from "themes/Theme";
import ClearSearch from "./ClearSearch";
import {SearchInfoContext} from "contexts/SearchInfoProvider";
import {useTranslation} from "react-i18next";

export default function SearchBar(props) {
  const {searchInfo, clearSearch, searchInputRef, searchedAddressRef} = useContext(SearchInfoContext);

  const {t} = useTranslation();

  //On search_bar blur, the address bar should reflect the searched address
  const handleBlur = val => {
    if (searchedAddressRef.current !== val.target.value) {
      return (searchInputRef.current.value = searchedAddressRef.current);
    }
  };

  return (
    <Flex {...props}>
      <Box as="form" onSubmit={e => e.preventDefault()} width="100%" sx={{
          borderRadius: "default"
        }}>
        <Input ref={searchInputRef} id="search_bar" name="search_bar" placeholder={t("address")} height={6} color="text" bg="white" pl={6} pr={[0, 0, 6]} sx={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='19' height='17' viewBox='0 0 19 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.98384 0L0 6.78974L1.25587 8.33286L2.2963 7.52041L2.19777 16.0485H5.34418V11.1104H8.79106V16.0485H16.6402L16.5813 8.3986L17.2682 8.95011L18.6543 7.39385L15.4973 4.72786L12.108 2.29827L8.98384 0Z' fill='%23${Theme.colors.primary.substring(1)}'/%3E%3C/svg%3E%0A");`, // to correctly format SVG for inline/CSS use, use https://yoksel.github.io/url-encoder/ or see about encoding via a script: https://stackoverflow.com/questions/23218174/how-do-i-save-export-an-svg-file-after-creating-an-svg-with-d3-js-ie-safari-an  https://krypted.com/utilities/html-encoding-reference/
            backgroundRepeat: "no-repeat",
            fill: "#FFFFFF",
            backgroundSize: [
              "15px", "15px", "20px"
            ],
            backgroundPosition: "12px",
            borderRadius: "default",
            borderColor: "white",
            fontFamily: `${Theme.fonts.body} !important`,
            display: "inline-block",
            fontSize: [1, 1, 3]
          }} onBlur={val => handleBlur(val)}/>
      </Box>
      {
        searchInfo.lat && (<Box width={0 / 1000}>
          <ClearSearch id="clearSearch" handleClick={clearSearch}/>
        </Box>)
      }
    </Flex>
  );
}
