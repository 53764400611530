import { Box, Flex, Text } from "rebass";
import { defaultExterior } from "icons";
import {
  determineSchoolGradeLevels,
  determineSchoolType
} from "utils/school-helpers";
import { Theme } from "themes/Theme"; // imported for height
import React from "react";
import MetaTags from "./MetaTags";
import SchoolPickerButton from './SchoolPickerButton'
import i18n from "i18n";

export default function SearchResult(props) {
  const { setActiveSchoolId, activeSchool, setAnimateMarker } = props;

  function sendMarker(school) {
    setAnimateMarker(school);
  }

  return (
    <Flex
      // Wrapper: All
      p={3}
      flexDirection="row"
      alignItems="stretch"
      width="100%"
      justifyContent="flex-start"
      sx={{
        borderBottomWidth: "1.5px",
        borderBottomStyle: "solid",
        borderColor: "black5",
        cursor: "pointer",
        ":hover": {
          backgroundColor: "primary10"
        }
      }}
      onClick={() => setActiveSchoolId(activeSchool.school_id)}
      onMouseEnter={() => sendMarker(activeSchool)}
      onMouseLeave={() => sendMarker()}
    >
      <Flex
        // Wrapper: Icon & Text
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        flex="4"
      >
        <Box pr={4}>
          <Text
            // School Name
            mb={1}
            variant="miniHeading"
            sx={{
              display: "flex",
              alignItems: "center"
            }}
          >
            {activeSchool.name}
            {activeSchool.activity_count ? (
              <div
                style={{
                  marginLeft: ".5em",
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                  padding: "2px",
                  background: Theme.colors.primary,
                  color: "#fff",
                  textAlign: "center",
                  font: "10px",
                  display: "inline-block"
                }}
                className="numberCircle"
              >
                {activeSchool.activity_count}
              </div>
            ) : null}
          </Text>
          <Flex flexDirection="row" alignItems="center">
            <Box
              sx={{
                marginTop: "4px",
                marginRight: 1,
                verticalAlign: "top",
                display: "inline-flex"
              }}
            >
              <svg
                width="15"
                height="15"
                viewBox="0 0 72 90"
                fill="none"
                stroke={Theme.colors["black40"]}
                strokeWidth="5"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="36"
                  cy="35"
                  r="26"
                  fill={Theme.colors[activeSchool.category]}
                />
              </svg>
            </Box>
            <Text variant="thickSmall" color="black40">
              {determineSchoolGradeLevels(activeSchool)}
              &nbsp;
            </Text>
            <Text
              // School Category
              variant="thickSmall"
              color="black40"
            >
              {determineSchoolType(activeSchool, i18n.language)}
            </Text>
          </Flex>
          <MetaTags school={activeSchool} language={i18n.language} />
        </Box>
      </Flex>{" "}
      {/* End Wrapper: Icon & Text */}
      <Box sx={{flex:"1", minHeight: 8}}>
        <Box
          // School Exterior Image
          sx={{
            flex: "1",
            backgroundImage: `url('${
              activeSchool.photo_url ? activeSchool.photo_url : defaultExterior
            }')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            boxSizing: "inherit",
            minHeight: 7
          }}
        />
        <SchoolPickerButton schoolId={activeSchool.school_id} searchResult={true} />
      </Box>
    </Flex>
  );
}
