import React, { useContext } from "react";
import { withTranslation, Trans } from "react-i18next";
import WindowToggleContext from "contexts/WindowToggleContext";
import ToggleButton from "./window/ToggleButton";
import { WINDOW_NAME } from "./GuideWindow";
import { FaInfoCircle } from "react-icons/fa"

export const GuideToggle = withTranslation()(({...props}) => {
  const {visibleWindow, openVisibleWindow, closeVisibleWindow} = useContext(WindowToggleContext);

  const active = visibleWindow === WINDOW_NAME;

  function handleChange() {
    if (active) {
      closeVisibleWindow()
    } else {
      openVisibleWindow(WINDOW_NAME)
    }
  };

  return (
    <ToggleButton Icon={FaInfoCircle} active={active} onClick={handleChange} {...props}>
      <Trans>
        Guide
      </Trans>
    </ToggleButton>
  );
});

export default GuideToggle;
