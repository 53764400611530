import React from "react";
import { withTranslation, Trans } from "react-i18next";
import ExternalLink from "./overlay/ExternalLink";

export const EnrollTulsaLink = withTranslation()(() => (
  <ExternalLink fontWeight={"bold"} href={"https://enroll.tulsaschools.org"}>
    <Trans>
      Apply now!
    </Trans>
  </ExternalLink>
));

export default EnrollTulsaLink;
