import React from 'react'
import { Theme } from 'themes/Theme'
import { Flex, Image, Link } from 'rebass'
import { FaExternalLinkAlt } from 'react-icons/fa'

function DetailsCardLink({ icon, alt, link, text, testId }) {
  return (
    <Flex alignItems="center">
      {icon && (
        <Image minWidth="initial" src={icon} alt={alt} mt={3} mr={2} width={4} />
      )}
      <Link
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        data-testid={testId}
        style={{ color: Theme.colors.primary }}
        ml={1}
        mt={icon ? 3 : 0}
      >
        {text}{' '}
        <FaExternalLinkAlt
          style={{ marginLeft: Theme.space[1], color: Theme.colors.primary }}
        />
      </Link>
    </Flex>
  )
}

export default DetailsCardLink
