import React from "react";
import ThemeProvider from "themes/ThemeProvider";
import { SchoolPickerProvider } from "./SchoolPickerContext";
import { SearchInfoProvider } from "./SearchInfoProvider";
import { SchoolDataProvider } from "./SchoolDataContext";
import { WindowToggleProvider } from "./WindowToggleContext"
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { QueryCache, ReactQueryCacheProvider } from "react-query";

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const AllProviders = ({ schoolPickerLimit = 6, waitForFonts = false, children }) => {
  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <ThemeProvider {...{waitForFonts}}>
        <SchoolDataProvider>
          <SchoolPickerProvider limit={schoolPickerLimit}>
            <WindowToggleProvider>
              <SearchInfoProvider>
                <DndProvider backend={HTML5Backend}>
                  {children}
                </DndProvider>
              </SearchInfoProvider>
            </WindowToggleProvider>
          </SchoolPickerProvider>
        </SchoolDataProvider>
      </ThemeProvider>
    </ReactQueryCacheProvider>
  );
};

export default AllProviders;
