// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set#Implementing_basic_set_operations

/* Examples

```javascript
let setA = new Set([1, 2, 3, 4])
let setB = new Set([2, 3])
let setC = new Set([3, 4, 5, 6])

isSuperset(setA, setB)          // => true
union(setA, setC)               // => Set [1, 2, 3, 4, 5, 6]
intersection(setA, setC)        // => Set [3, 4]
symmetricDifference(setA, setC) // => Set [1, 2, 5, 6]
difference(setA, setC)          // => Set [1, 2]
```
*/

/**
 * Is set a superset
 *
 * Set contains everything in subset.
 *
 * Example:
 *
 * ```javascript
 * let setA = new Set([1, 2, 3, 4])
 * let setB = new Set([2, 3])
 * let setC = new Set([3, 4, 5, 6])
 * isSuperset(setA, setB)          // => true
 * ```
 *
 * @param {*} set
 * @param {*} subset
 */
export function isSuperset(set, subset) {
  for (let elem of subset) {
      if (!set.has(elem)) {
          return false
      }
  }
  return true
}

/**
 * Union of sets
 *
 * Returns a new set of everything in both sets.
 *
 * Example:
 *
 * ```javascript
 * let setA = new Set([1, 2, 3, 4])
 * let setB = new Set([2, 3])
 * let setC = new Set([3, 4, 5, 6])
 *
 * union(setA, setC)               // => Set [1, 2, 3, 4, 5, 6]
 * ```
 *
 * @param {*} setA
 * @param {*} setB
 */
export function union(setA, setB) {
  let _union = new Set(setA)
  for (let elem of setB) {
      _union.add(elem)
  }
  return _union
}

/**
 * Intersection of sets
 *
 * Returns a new set of everything contained in both sets.
 *
 * Example:
 *
 * ```javascript
 * let setA = new Set([1, 2, 3, 4])
 * let setB = new Set([2, 3])
 * let setC = new Set([3, 4, 5, 6])
 *
 * intersection(setA, setC)        // => Set [3, 4]
 * ```
 *
 * @param {*} setA
 * @param {*} setB
 */
export function intersection(setA, setB) {
  let _intersection = new Set()
  for (let elem of setB) {
      if (setA.has(elem)) {
          _intersection.add(elem)
      }
  }
  return _intersection
}

/**
 * Symmetric difference of sets
 *
 * Returns a new set of everything not in both sets.
 *
 * Example:
 *
 * ```javascript
 * let setA = new Set([1, 2, 3, 4])
 * let setB = new Set([2, 3])
 * let setC = new Set([3, 4, 5, 6])
 *
 * symmetricDifference(setA, setC) // => Set [1, 2, 5, 6]
 * ```
 *
 * @param {*} setA
 * @param {*} setB
 */
export function symmetricDifference(setA, setB) {
  let _difference = new Set(setA)
  for (let elem of setB) {
      if (_difference.has(elem)) {
          _difference.delete(elem)
      } else {
          _difference.add(elem)
      }
  }
  return _difference
}

/**
 * Difference of sets
 *
 * Returns a new set of everything in the first set not in the second set.
 *
 * Example:
 *
 * ```javascript
 * let setA = new Set([1, 2, 3, 4])
 * let setB = new Set([2, 3])
 * let setC = new Set([3, 4, 5, 6])
 *
 * difference(setA, setC)          // => Set [1, 2]
 * ```
 *
 * @param {*} setA
 * @param {*} setB
 */
export function difference(setA, setB) {
  let _difference = new Set(setA)
  for (let elem of setB) {
      _difference.delete(elem)
  }
  return _difference
}
