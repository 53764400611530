import React, { useContext, useEffect, useState } from "react";
import { Map, Polygon, InfoWindow, GoogleApiWrapper } from "google-maps-react";
import { Flex, Text } from "rebass";
import { home } from "icons";
import { SearchInfoContext } from "contexts/SearchInfoProvider";
import QuestionHelp from "./QuestionHelp";
import MarkerWrapper from "./MarkerWrapper";
import { useTranslation } from "react-i18next";
import { selectSchoolIcon } from "utils/map-helpers";
import SchoolDataContext from "contexts/SchoolDataContext";

const GoogleMap = props => {
  const {
    animateMarker,
    onGoogleMapReady,
    setActiveSchoolId,
    google,
    mapZoom,
    resetGeometries,
    schoolGeometries,
    schoolGeometry,
    inBounds
  } = props;

  const { t } = useTranslation();

  const [activeMarker, setActiveMarker] = useState(null);
  const [activeSchoolName, setActiveSchoolName] = useState(null);
  const { searchInfo } = useContext(SearchInfoContext);
  const { districtPolygon } = useContext(SchoolDataContext);
  const [showInfoWindow, setShowInfoWindow] = useState(false);

  const onMarkerEnter = (props, marker, e) => {
    const markerChanged =
      !activeMarker || activeMarker.schoolId !== marker.schoolId;
    if (markerChanged) {
      handleActiveMarkerChange(marker);
    }
  };

  const onMarkerExit = (props, marker, e) => {
    setShowInfoWindow(false);
    setActiveMarker(null);
  };

  const createHomeMarker = searchInfo => {
    let homeMarker = (
      <MarkerWrapper
        id="searched-address"
        style={{
          zIndex: "10000"
        }}
        position={{
          lat: searchInfo.lat,
          lng: searchInfo.lng
        }}
        icon={{
          url: home
        }}
      />
    );
    return searchInfo.lat ? homeMarker : null;
  };

  const handleActiveMarkerChange = newActiveMarker => {
    setActiveMarker(newActiveMarker);
    setActiveSchoolName(newActiveMarker.schoolName);
    setShowInfoWindow(true);
  };

  // This side effect is if the user searches an address but their mouse
  // is currently hovering over a school and showing an infowindow.
  // Otherwise the school zone boundary will cause an out of bounds error.
  useEffect(() => {
    setActiveMarker(null);
    setActiveSchoolName(null);
    setShowInfoWindow(false);
  }, [schoolGeometries]);

  const handleMapClick = () => {
    if (searchInfo.address || schoolGeometry) {
      resetGeometries();
    }
  };

  const createMarkers = g => {
    return (
      <MarkerWrapper
        key={g.id}
        className="tooltip"
        onMouseover={onMarkerEnter}
        onMouseout={onMarkerExit}
        icon={{
          url: selectSchoolIcon(g.category, g.is_early_childhood)
        }}
        position={{
          lat: g.lat,
          lng: g.lng
        }}
        schoolId={g.id}
        schoolName={g.name}
        onClick={() => setActiveSchoolId(g.school_id)}
        animation={animateMarker && g.school_id === animateMarker.school_id}
      />
    );
  };

  return (
    <Map
      google={google}
      onReady={onGoogleMapReady}
      // NOTE: Mayo Demonstration (36.14691,-95.95042) is close to center
      initialCenter={{
        lat: 36.14691 + 0.01,
        lng: -95.95042 + 0.01
      }}
      zoom={mapZoom}
      mapTypeControl={false}
      fullscreenControl={false}
      onClick={handleMapClick}
    >
      {createHomeMarker(searchInfo)}

      {schoolGeometries && schoolGeometries.map(g => g && createMarkers(g))}

      <InfoWindow marker={activeMarker} visible={showInfoWindow}>
        <div>{activeSchoolName}</div>
      </InfoWindow>

      {schoolGeometry && schoolGeometry.polygon && (
        <Polygon
          key={schoolGeometry.id}
          paths={schoolGeometry.polygon}
          fillOpacity={0.25}
        />
      )}

      {!inBounds.current && (
        <Polygon
          paths={districtPolygon.polygon}
          fillOpacity={0.25}
        />
      )}

      <Flex
        sx={{
          position: "absolute",
          right: "60px",
          bottom: "40px"
        }}
      >
        <QuestionHelp>
          <Text>{t("contact_enrollment")}</Text>
          <Text>{t("enrollment_hours")}</Text>
          <Text>
            <a href="tel:918-728-3972">918-728-3972</a>
          </Text>
          <Text>
            <a href={"mailto:enroll@tulsaschools.org?cc=robintr@tulsaschools.org&subject=FindASchool%20Question"}>
              enroll@tulsaschools.org
            </a>
          </Text>
        </QuestionHelp>
      </Flex>
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY
})(GoogleMap);
