import React, { useReducer } from "react";
import schoolPickerReducer from "reducers/school-picker-reducer";

export const SchoolPickerContext = React.createContext();

export const SchoolPickerProvider = ({limit, ...props}) => {
  // State
  const [schoolPicker, dispatch] = useReducer(
    schoolPickerReducer,
    schoolPickerReducer({ limit }, { type: "init" })
  );

  // Context Shortcuts
  const has = (value) => schoolPicker.list.has(value);
  const values = () => schoolPicker.list.values();
  const isFull = () => schoolPicker.list.isFull();

  // Context Modifiers
  const clear = () => dispatch({type: "clear"});
  const add = (value) => dispatch({type: "add", payload: value});
  const remove = (value) => dispatch({type: "remove", payload: value});
  const replace = (values) => dispatch({type: "replace", payload: values});

  // Render
  return (
    <SchoolPickerContext.Provider value={{
      ...schoolPicker, // list, limit, visible
      has, values, isFull,
      add, remove, replace, clear,
      [Symbol.iterator]: values
    }}>
      {props.children}
    </SchoolPickerContext.Provider>
  );
};

export const withSchoolPicker = (Component) => {
  return (props) => (
    <SchoolPickerContext.Consumer>
      {(value) => <Component schoolPicker={value} {...props} />}
    </SchoolPickerContext.Consumer>
  );
};

export default SchoolPickerContext;
