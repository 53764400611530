import axios from "axios";
import { RANDOM_BUILD_KEY } from "utils/meta-env";

const BASE_URL_API = process.env.REACT_APP_API_URL || '';
const BASE_URL_DATA = process.env.PUBLIC_URL || '';

const cache_buster = RANDOM_BUILD_KEY ? `?${RANDOM_BUILD_KEY}` : ''

export const client = axios.create({});

client.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    console.error(`Request failed: ${error}`);
    return Promise.reject(error);
  }
);

export default {
  schools: {
    get(id) {
      if (id) {
        return client.get(`${BASE_URL_DATA}/data/schools/${id}.json${cache_buster}`);
      }

      return client.get(`${BASE_URL_DATA}/data/schools.json${cache_buster}`);
    }
  },
  polygons: {
    get() {
      return client.get(`${BASE_URL_DATA}/data/polygons.json${cache_buster}`);
    }
  },
  activities: {
    get() {
      return client.get(`${BASE_URL_DATA}/data/activities.json${cache_buster}`);
    }
  },
  logSearch(params) {
    if (process.env.NODE_ENV === "development") {
      return Promise.resolve()
    }

    return client.post(`${BASE_URL_API}/school_locator/search_logs`, {
      ...params
    });
  },
  emailList(params) {
    return client.post(`${BASE_URL_API}/school_locator/email_lists`, params)
  }
};
