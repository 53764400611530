import React from "react";
import { Box, Card, Heading, Flex, Text, Link, Button } from "rebass";
import HorizontalRule from "./HorizontalRule";

export default function OutOfBoundsCard(props) {
  const { clearAllSearchParams } = props;
  return (
    <Box width={256}>
      <Card
        sx={{
          background: "white",
          borderRadius: 10,
          boxShadow: "0 0 16px rgba(0, 0, 0, .15)",
          position: "absolute",
          top: "15%",
          right: "24px",
          left: "24px",
          p: 4
        }}
      >
        <Flex pt={4} p={2} flexDirection="column">
          <Heading variant="h1">No Schools Found for Search</Heading>
          <HorizontalRule mt={3} />
          <Box mt={3}>
            <Text>
              Either no schools match the filters entered, or you live outside
              of Tulsa Public Schools Boundary.
            </Text>
            <Text>
              For out of district enrollment, please visit our{" "}
              <Link
                href="https://www.tulsaschools.org/parents-students/enrolltransfer/k-12"
                target="_blank"
                rel="noopener noreferrer"
              >
                website
              </Link>{" "}
              to learn how to apply.
            </Text>
            <br />
            <Button variant="primary" onClick={clearAllSearchParams}>
              Reset Search
            </Button>
          </Box>
        </Flex>
      </Card>
    </Box>
  );
}
