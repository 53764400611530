// NOTE: Mayo Demonstration (36.14691,-95.95042) is close to center

export const TULSA_LAT = 36.14691;
export const TULSA_LNG = -95.95042;
export const TULSA_BOUND_RADIUS = 0.5;

export const TULSA_CENTER = {
  lat: TULSA_LAT,
  lng: TULSA_LNG,
};

export const TULSA_SW =  {
  lat: TULSA_CENTER.lat - TULSA_BOUND_RADIUS,
  lng: TULSA_CENTER.lng - TULSA_BOUND_RADIUS
};

export const TULSA_NE = {
  lat: TULSA_CENTER.lat + TULSA_BOUND_RADIUS,
  lng: TULSA_CENTER.lng + TULSA_BOUND_RADIUS
};

export const TULSA_SE = {
  lat: TULSA_SW.lat,
  lng: TULSA_NE.lng
};

export const TULSA_NW = {
  lat: TULSA_NE.lat,
  lng: TULSA_SW.lng
};

export const TULSA_BOUNDS = [ TULSA_SW, TULSA_NE ];
