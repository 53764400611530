import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import "./index.css";
import "./i18n";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import gtag from "./google-analytics"

gtag(process.env.REACT_APP_GOOGLE_ANALYTICS_CODE);

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://a1aa2cb970ec445d95965b7b486b705c@sentry.io/168373"
  });
}

ReactDOM.render(<App waitForFonts={true} />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
