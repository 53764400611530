import { titleize } from "./language-helpers";
import i18n from "i18n";

let languageSettings = i18n;

export const determineSchoolGradeLevels = school => {
  //if statement is needed in case a user is looking at
  // a single school then enters an address
  if (school.grade_levels) {
    let arrLength = school.grade_levels.length;
    if (arrLength === 0) {
      return null;
    } else if (arrLength === 1) {
      return `${titleize(school.grade_levels[0])}, `;
    } else if (school.grade_levels[0] === "pre-k (3)") {
      // in the interest of saving space, and because of the limited number of pre-K three year olds, we're leaving this distinction for parental investigation.
      return `pre-k - ${school.grade_levels[arrLength - 1]}, `;
    }
    return `${titleize(school.grade_levels[0])} - ${titleize(
      school.grade_levels[arrLength - 1]
    )}, `;
  }
};

export const determineSchoolType = (school, language) => {
  languageSettings.language = language;
  return ` ${languageSettings.t(school.category)}`;
};

// Add criteria/lottery booleans here…
// tags are passed to front end as array [text, color (default is null), textDecorationLine (default is null)]
export const determineSchoolMetaTags = (school, language) => {
  languageSettings.language = language;
  let metaTags = [];
  if (school.is_neighborhood) {
    metaTags.push([languageSettings.t("neighborhood")]);
  }
  if (school.is_magnet) {
    metaTags.push([languageSettings.t("magnet")]);
  }
  if (school.is_charter) {
    metaTags.push([languageSettings.t("charter")]);
  }
  if (school.is_alternative) {
    metaTags.push([languageSettings.t("alternative")]);
  }
  return metaTags;
};

export const schoolInfoType = (schoolInfo) => {
  if (schoolInfo.is_alternative) return "alternative";
  if (schoolInfo.is_charter) return "charter";
  if (schoolInfo.is_magnet) return "magnet";
  if (schoolInfo.is_neighborhood) return "neighborhood";
}

export const SCHOOL_TYPES = ["elementary", "middle", "high", "early_childhood"];

export const SPECIAL_APPLICATION_CRITERIA_SCHOOLS = ["charter", "magnet"];

export const schoolIsOfType = (school, { gradeLevels = [], schoolTypes = [] }) => {
  const type_or_category = (type) => school[`is_${type}`] || (school.category.indexOf(type) !== -1)

  const inGradeLevels = !gradeLevels.length || gradeLevels.some(type_or_category)
  const inSchoolTypes = !schoolTypes.length || schoolTypes.some(type_or_category)

  return inGradeLevels && inSchoolTypes
}

export const filterSchoolsByType = (schools, types) => {
  if (!types || !types.length) {
    return schools;
  }

  return schools.filter((school) => {
    return schoolIsOfType(school, { schoolTypes: types });
  });
};
