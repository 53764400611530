import React from "react";
import { Link as RebassLink } from "rebass";

export const Link = (props) => {
  const sx = props.sx || {};
  sx.color = "primary";
  sx.textDecoration= "none";
  sx["&:hover"] = {
    textDecoration: "underline",
    ...sx["&:hover"] || {}
  };
  return (
    <RebassLink variant={"flat"} {...props} sx={sx} />
  );
}

export default Link;
