import { schoolTypeInitialToggleState, schoolTypeReducer } from "reducers/school-type-toggle-reducer";
import { gradeLevelInitialToggleState, gradeLevelReducer } from "reducers/grade-level-toggle-reducer";
import { pointInPolygon } from "utils/map-helpers";
import { SCHOOL_TYPES, schoolIsOfType } from "utils/school-helpers";
import { SearchInfoContext } from "contexts/SearchInfoProvider";
import apiClient from "api";
import Embed from "components/map/Embed";
import NavBar from "components/navbar/NavBar";
import React, { useState, useEffect, useRef, useReducer, useContext } from "react";
import SchoolInfo from "components/info-box/SchoolInfo";
import SchoolInfoHeading from "components/info-box/SchoolInfoHeading";
import { Box, Flex } from "rebass";
import { sortSchoolGeometries } from "utils/map-helpers";
import { useQuery } from "react-query";
import { isEventKeyBackspace, isEventKeyEsc } from "utils";
import SchoolDataContext from "contexts/SchoolDataContext";
import { intersection as setIntersection } from "utils/set-operations";
import { TULSA_CENTER, TULSA_BOUNDS } from "utils/tulsa"
import GuideWindow from "components/map/GuideWindow";
import MyListWindow from "components/map/MyListWindow";
import { useHistory, useParams } from "react-router-dom";

const schoolCategoriesFromState = (toggleState) => {
  const { removeElementary, removeMiddle, removeHigh, removeEarlyChildhood } = toggleState;
  const schoolState = {
    elementary: !removeElementary,
    middle: !removeMiddle,
    high: !removeHigh,
    early_childhood: !removeEarlyChildhood
  };

  return SCHOOL_TYPES.filter(s => !schoolState[s]);
};

// Logs a snapshot of the current search state.
//
const logSearch = ({ activityIds, searchInfo, gradeLevelToggleState, schoolTypeToggleState }) => {
  const { address } = searchInfo;

  const { removeNeighborhood, removeMagnet, removeCharter, removeAlternative } = schoolTypeToggleState;

  let show_neighborhood_schools = removeNeighborhood,
    show_magnets = removeMagnet,
    show_charters = removeCharter,
    show_alternatives = removeAlternative

  if (!show_neighborhood_schools && !show_magnets && !show_charters && !show_alternatives) {
    show_neighborhood_schools = show_magnets = show_charters = show_alternatives = true;
  } // if [nothing selected]

  apiClient.logSearch({
    address,
    show_neighborhood_schools,
    show_magnets,
    show_charters,
    show_alternatives,
    school_categories: schoolCategoriesFromState(gradeLevelToggleState),
    activity_ids: activityIds
  });
};

export default function Home(props) {
  const { slug } = useParams();
  const history = useHistory();

  // State

  const [activityIds, setActivityIds] = useState([]);
  const [animateMarker, setAnimateMarker] = useState(null);
  const [mapZoom, setMapZoom] = useState(12);
  const [schoolGeometries, setSchoolGeometries] = useState([]);
  const [schoolGeometry, setSchoolGeometry] = useState(null);
  const { searchInfo, setSearchInfo, searchInputRef } = useContext(SearchInfoContext);
  const [schoolTypeToggleState, schoolTypeDispatch] = useReducer(schoolTypeReducer, schoolTypeInitialToggleState);
  const [gradeLevelToggleState, gradeLevelDispatch] = useReducer(gradeLevelReducer, gradeLevelInitialToggleState);
  const [nearbySchools, setNearbySchools] = useState([]);
  const [activeSchoolId, _setActiveSchoolId] = useState(null);
  const inBounds = useRef(true);
  const mappyMap = useRef(null);
  const { schools, polygons, districtPolygon, schoolIdToSlug, schoolSlugToId } = useContext(SchoolDataContext);

  const convertSchoolToGeometry = (activityIdsSet, polygons) => {
    return (school) => {
      const school_id = school.id;
      const lat = school.geometry.lat;
      const lng = school.geometry.lng;
      const polygon = polygons[school.id].polygon;
      const activity_count = setIntersection(new Set(school.activity_ids), activityIdsSet).size;

      // id: was geometry.id
      // has_lottery_admissions: not used
      // has_criteria_admissions: not used
      // mid_id: not used
      // high_id: not used

      return {
        ...school,
        school_id,
        lat,
        lng,
        polygon,
        activity_count,
      };
    };
  }

  const { data: geometries } = useQuery(
    ['geometries', activityIds, gradeLevelToggleState, schoolTypeToggleState, polygons],
    async (_key, activityIds, gradeLevelToggleState, schoolTypeToggleState, polygons) => {
      const activityIdsSet = new Set(activityIds);
      const gradeLevels = schoolCategoriesFromState(gradeLevelToggleState);
      const schoolTypes = []

      if (schoolTypeToggleState.removeNeighborhood) schoolTypes.push('neighborhood');
      if (schoolTypeToggleState.removeMagnet) schoolTypes.push('magnet');
      if (schoolTypeToggleState.removeCharter) schoolTypes.push('charter');
      if (schoolTypeToggleState.removeAlternative) schoolTypes.push('alternative');

      const data = Object.values(schools)
        .filter((school) => {
          const intersection = setIntersection(new Set(school.activity_ids), activityIdsSet);
          const a = (activityIdsSet.size === 0) || Boolean(intersection.size);
          const b = schoolIsOfType(school, { gradeLevels, schoolTypes });
          return a && b;
        })
        .map(convertSchoolToGeometry(activityIdsSet, polygons));

      return sortSchoolGeometries(data);
    },
    {
      enabled: Object.keys(schools).length && Object.keys(polygons).length,
      initialData: [],
      initialStale: true
    }
  );

  const { data: schoolInfo } = useQuery(
    ["school-info", activeSchoolId],
    async (_key, id) => {
      const res = await apiClient.schools.get(id);
      return res.data.school_profile;
    },
    {
      enabled: activeSchoolId
    }
  );

  // Helpers

  const onGoogleMapReady = (mapProps, map) => {
    mappyMap.current = map;

    const { google } = mapProps;
    const searchBox = new google.maps.places.SearchBox(searchInputRef.current);

    searchBox.setBounds(new google.maps.LatLngBounds(...TULSA_BOUNDS));

    google.maps.event.addListener(searchBox, "places_changed", function () {
      if (searchInputRef.current.value.trim() === "") {
        searchInputRef.current.value = "";
        return resetGeometries();
      }
      setSearchLatLngAddress(searchBox.getPlaces(), map);
    });
  };

  const isOnLandingPage = () => geometries.length !== schoolGeometries.length;

  const recenterMap = () => {
    mappyMap.current && mappyMap.current.setCenter(TULSA_CENTER);
  };

  const clearAllSearchParams = () => {
    setSearchInfo({ lat: null, lng: null, address: "" });
    searchInputRef.current.value = "";
    setMapZoom(12);
    recenterMap();
  };

  const setSearchLatLngAddress = (places, map) => {
    const lat = places[0].geometry.location.lat();
    const lng = places[0].geometry.location.lng();
    const address = places[0].formatted_address;

    setSearchInfo({ lat, lng, address });
    map.setCenter({ lat, lng });
    setMapZoom(13);
  };

  const resetGeometries = () => {
    setActiveSchoolId(null);
  };

  const keyUpEventListener = (event) => {
    // ESC
    if (isEventKeyEsc(event) && schoolGeometry) {
      resetGeometries();
    }

    // BACKSPACE
    if (isEventKeyBackspace(event) && isOnLandingPage() && !searchInputRef.current.value.trim()) {
      clearAllSearchParams();
    }
  };

  const setActiveSchoolId = (id) => {
    if (id) {
      const slug = schoolIdToSlug(id, null);
      if (slug) {
        history.push(`/school/${slug}`);
        return;
      }
    }

    history.push('/');
  };

  // Effects

  useEffect(() => {
    document.addEventListener("keyup", keyUpEventListener, false);

    return () => {
      document.removeEventListener("keyup", keyUpEventListener, false);
    };
  });

  useEffect(() => {
    logSearch({ activityIds, searchInfo, gradeLevelToggleState, schoolTypeToggleState });
  }, [activityIds, searchInfo, gradeLevelToggleState, schoolTypeToggleState]);

  useEffect(() => {
    setAnimateMarker(null);
    if (schoolInfo) {
      const geometry = geometries.find((geometry) => geometry.school_id === schoolInfo.id);
      setSchoolGeometries([geometry]);
      setSchoolGeometry(geometry);
    } else {
      setSchoolGeometries(geometries);
      setSchoolGeometry(null);
    }
  }, [geometries, schoolInfo]);

  useEffect(() => {
    if (searchInfo && searchInfo.lat) {
      inBounds.current = pointInPolygon(districtPolygon.polygon, {
        lat: searchInfo.lat,
        lng: searchInfo.lng
      });
    } else {
      inBounds.current = true
    }
  }, [districtPolygon, searchInfo]);

  useEffect(() => {
    if (searchInfo && searchInfo.lat && inBounds.current) {
      const point = { lat: searchInfo.lat, lng: searchInfo.lng };
      setNearbySchools(geometries.filter(g => g.is_neighborhood && g.polygon && pointInPolygon(g.polygon, point)))

      const element = document.getElementById('your-neighborhood-schools')

      if (element) {
        element.scrollIntoView()
      }
    } else {
      setNearbySchools([])
    }
  }, [geometries, searchInfo, inBounds])

  useEffect(() => {
    try {
      const id = (Object.keys(schools).length && slug) ? schoolSlugToId(slug) : null
      _setActiveSchoolId(id)
    } catch (error) {
      history.push('/')
    }
  }, [schools, slug, schoolSlugToId, history]);

  // Render

  return (
    <Flex sx={{
      width: "100%",
      height: "100%",
      flexDirection: "column"
    }}>
      <NavBar
        activeSchoolId={activeSchoolId}
        clearAllSearchParams={clearAllSearchParams}
        gradeLevelDispatch={gradeLevelDispatch}
        schoolTypeDispatch={schoolTypeDispatch}
        setActivityIds={setActivityIds}
        setMapZoom={setMapZoom}
      />
      <Flex flex={1}>
        <Box flexGrow={0.1} sx={{
          width: ["100%", 13, 13, 13, 14],
          position: "relative",
        }}>
          <Flex
            flexDirection="column"
            sx={{
              background: "white",
              boxShadow: "small",
              position: "absolute",
              height: "100%",
              width: "100%"
            }}
          >
            <SchoolInfoHeading {...{
              inBounds,
              resetGeometries,
              schoolGeometries,
              schoolInfo,
              searchInfo
            }} />

            <SchoolInfo {...{
              clearAllSearchParams,
              setActiveSchoolId,
              inBounds,
              nearbySchools,
              schoolGeometries,
              schoolInfo,
              searchInfo,
              setAnimateMarker,
            }} />

            <GuideWindow display={["block", "none"]} />
            <MyListWindow display={["block", "none"]} setActiveSchoolId={setActiveSchoolId} />
          </Flex>
        </Box>
        <Box flexGrow={1} sx={{
          display: ["none", "block"],
          height: "100%",
          position: "relative"
        }}>
          <Embed
            animateMarker={animateMarker}
            onGoogleMapReady={onGoogleMapReady}
            setActiveSchoolId={setActiveSchoolId}
            inBounds={inBounds}
            mapZoom={mapZoom}
            resetGeometries={resetGeometries}
            schoolGeometries={schoolGeometries}
            schoolGeometry={schoolGeometry}
          />
        </Box>
      </Flex>
    </Flex>
  );
}
