const Theme = {
  breakpoints: ["40em", "52em", "64em"],

  colors: {
    text: "#000",
    background: "#fff",
    primary: "#0096D7",
    secondary: "#30c",
    white: "#FFFFFF",
    white10: "rgb(255, 255, 255, 0.1)",
    white60: "rgb(255, 255, 255, 0.6)",
    fuschia: "#dd00cc",
    black100: "rgb(33, 36, 41, 1)",
    black95: "rgb(33, 36, 41, 0.95)",
    black90: "rgb(33, 36, 41, 0.9)",
    black60: "rgb(33, 36, 41, 0.6)",
    black40: "#969b9e",
    black20: "#dddddf",
    black5: "#f0f3f5",
    primary120: "rgb(2, 179, 255)",
    primary75: "rgb(0, 137, 195, 0.75)",
    primary50: "rgb(0, 137, 195, 0.50)",
    primary25: "rgb(0, 137, 195, 0.25)",
    primary10: "rgb(0, 137, 195, 0.1)",
    transparent: "rgb(0, 137, 195, 0.0)",
    alternative: "#56B2C4",
    charter: "#56B2C4",
    elementary: "#FFB000",
    high: "#DC267F",
    magnet: "#59a2e5",
    middle: "#FE6100",
    elementary_middle: "#FE6100",
    early_childhood: "#DDF05E"
  },

  space: [0, 4, 8, 16, 24, 32, 40, 48, 64, 128, 256, 512],
  sizes: [
    0,
    4,
    8,
    16,
    24,
    32,
    40,
    48,
    64,
    72,
    128,
    192,
    256,
    384,
    512,
    640,
    1024
  ],
  radii: {
    none: 0,
    default: 8,
    circle: 99999
  },
  shadows: {
    small: "0 0 4px rgba(0, 0, 0, .125)",
    large: "0 0 24px rgba(0, 0, 0, .125)",
    window: "0px 1px 8px rgba(0, 0, 0, 0.25)",
    menu: "0 0 24px rgba(0, 0, 0, .50)",
  },
  fonts: {
    body: "Inter, sans-serif",
    heading: "Inter, sans-serif"
  },
  fontSizes: [10, 12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 600,
    semiBold: 500
  },
  lineHeights: {
    body: 1.6,
    heading: 1.25
  },
  maxReadingWidth: '45em',

  // Rebass variants
  text: {
    // for Rebass Text and Heading
    h1: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: [5, 6],
      color: "black95"
    },
    h2: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "bold",
      fontSize: [2, 3],
      color: "black95"
    },
    body: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      fontSize: [1, 2, 3],
      color: "black95"
    },
    fontSizes: [10, 12, 14, 16, 20, 24, 32, 48, 64, 96],
    fontWeights: {
      body: 400,
      heading: 700,
      semiBold: 600,
      bold: 700
    },
    caps: {
      textTransform: "uppercase",
      letterSpacing: "0.1em"
    },
    thickSmall: {
      fontSize: 1,
      textTransform: "uppercase",
      fontWeight: "bold"
    },
    miniHeading: {
      fontFamily: "body",
      lineHeight: "heading",
      fontSize: [3],
      fontWeight: "semiBold"
    },
    small: {
      fontSize: 1
    }
  },
  variants: {
    // for Rebass Box, Flex, Link, Image, Card
    link: {
      fontFamily: "body",
      color: "black95",
      fontSize: [1, 2],
      textDecoration: "none",
      "&:hover": {
        color: "primary",
        textDecoration: "underline"
      }
    }
  },
  buttons: {
    // for Rebass buttons
    primary: {
      // in button component use prop e.g. variant="primary"
      fontSize: 3,
      fontFamily: "body",
      height: 6,
      color: "white",
      bg: "primary",
      borderRadius: "default",
      cursor: "pointer",
      ":hover": {
        backgroundColor: "primary120"
      }
    },
    outline: {
      variant: "buttons.primary",
      color: "white",
      bg: "transparent",
      border: "1px solid",
      borderColor: "primary",
      ":hover": {
        backgroundColor: "black20",
      }
    },
    full: {
      variant: "buttons.primary",
      width: "100%"
    },
    outlineFull: {
      variant: "buttons.outline",
      width: "100%"
    },
    outlineDarkFull: {
      variant: "buttons.outlineDark",
      width: "100%"
    },
    clear: {
      color: "white",
      bg: "transparent",
      borderColor: "primary",
      ":hover": {
        backgroundColor: "primary25",
        color: "white"
      }
    },
    flat: {
      backgroundColor: "white",
      borderColor: "black",
      border: "2px solid",
      borderRadius: "none",
      color: "black",
      cursor: "pointer",
      fontSize: 2,
      fontFamily: "body",
      px: 2,
      py: 1,
      ":hover": {
        backgroundColor: "black20",
        borderColor: "black",
      },
      "&.active": {
        backgroundColor: "primary",
        borderColor: "black",
        color: "white"
      }
    },
    toggle: {
      bg: "white",
      color: "primary",
      ":hover": {
        bg: "black20"
      },
      "&.active": {
        bg: "primary",
        color: "white",
      },
      "&.active:hover": {
        bg: "primary120"
      }
    }
  },
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body"
    }
  }
};

export { Theme };
