import React, { useEffect, useRef, useState } from 'react'
import { Box, Flex, Text, Button } from 'rebass'
import { Input } from '@rebass/forms'
import { Theme } from 'themes/Theme'
import api from 'api'
import { useMutation } from 'react-query'
import i18n from "i18next"
import { withTranslation, Trans } from 'react-i18next'

export const EmailForm = withTranslation()(({ schools, ...props }) => {
  const [email, setEmail] = useState('')
  const [reminder, setReminder] = useState(true)
  const [statusText, setStatusText] = useState('')
  const [statusColor, setStatusColor] = useState(Theme.colors.primary)
  const emailInputRef = useRef(null)

  const [
    mutate,
    { data: mutationResponse, error: mutationError },
  ] = useMutation(api.emailList)

  const validateForm = () => {
    const validEmail = emailInputRef.current.checkValidity()
    if (schools.length === 0) {
      setStatusText('Please select at least one school')
      setStatusColor(Theme.colors.fuschia)
      return false
    }

    if (email === '' || !validEmail) {
      setStatusText('Please enter a valid email')
      setStatusColor(Theme.colors.fuschia)
      return false
    }

    return true
  }

  const sendEmail = (e) => {
    e.preventDefault()
    const formIsValid = validateForm()
    if (formIsValid) {
      const schoolList = schools.map((school) => school.id)
      const params = { email: email, school_ids: schoolList, reminder: reminder, language: i18n.language }
      mutate(params)
    }
  }

  useEffect(() => {
    if (mutationResponse) {
      setStatusColor(Theme.colors.primary)
      setStatusText(mutationResponse.data.message)
    } else if (mutationError) {
      setStatusText('An error occurred. Please try again later.')
    }
  }, [mutationResponse, mutationError])

  return (
    <React.Fragment>
      <Text variant={'small'}>
        <Trans>
          Or send this list to yourself for later:
        </Trans>
      </Text>
      <Box as="form" onSubmit={(e) => e.preventDefault()}>
        <Flex
          flexGrow={1}
          sx={{
            backgroundColor: 'white',
          }}
        >
          <Flex flexGrow={1} alignItems={'center'}>
            <Flex flexGrow={1} pt={2} pb={2} pr={0} sx={{}}>
              <React.Fragment>
                <Box sx={{ flexGrow: 1 }}>
                  <Input
                    ref={emailInputRef}
                    name="email"
                    type="email"
                    data-testid="email-input"
                    placeholder="Email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{
                      borderColor: Theme.colors.black40,
                      boxShadow: '0px 1px 4px 0px #000000 25% inset',
                    }}
                  />
                </Box>
              </React.Fragment>
            </Flex>
            <Button
              ml={2}
              variant={'primary'}
              data-testid={'email-button'}
              sx={{
                backgroundColor: 'primary',
              }}
              onClick={sendEmail}
            >
              <Trans>
                Send
              </Trans>
            </Button>
          </Flex>
        </Flex>
        {!!statusText && (
          <Box mb={1}>
            <Flex>
              <Text
                variant={'small'}
                sx={{ color: statusColor }}
                data-testid="email-status"
              >
                <Trans>
                  {statusText}
                </Trans>
              </Text>
            </Flex>
          </Box>
        )}
        <Box>
          <Flex alignItems={"center"}>
            <input
              style={{ marginLeft: 0 }}
              id="remember"
              type="checkbox"
              name="remember"
              defaultChecked={reminder}
              onClick={(e) => setReminder(!reminder)}
            />
            <Text variant={'small'}>
              <Trans>
                Notify me before the enrollment deadline occurs.
              </Trans>
            </Text>
          </Flex>
        </Box>
      </Box>
    </React.Fragment>
  )
});

export default EmailForm;
