import { Marker } from "google-maps-react";

export default class MarkerWrapper extends Marker {
  mapUpdated = prevProps => {
    return this.props.map !== prevProps.map;
  };

  positionUpdated = prevProps => {
    const { position } = this.props;
    const latChanged = position.lat !== prevProps.position.lat;
    const lngChanged = position.lng !== prevProps.position.lng;
    return latChanged || lngChanged;
  };

  componentDidUpdate(prevProps) {
    if (this.props.animation) {
      this.marker.setAnimation(window.google.maps.Animation.BOUNCE);
    } else {
      this.marker.setAnimation(null);
    }
    if (this.mapUpdated(prevProps) || this.positionUpdated(prevProps)) {
      if (this.marker) {
        this.marker.setMap(null);
      }
      this.renderMarker();
    }
  }
}
