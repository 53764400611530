import React from "react";
import Button from "components/map/overlay/Button";
import { Theme } from "themes/Theme";
import PropTypes from "prop-types";
import { Box, Flex } from "rebass";

export const ToggleButton = ({Icon, children, active = false, sx={}, ...props}) => {
  const iconStyle = {
    backgroundColor: "transparent",
    color: active ? "white" : Theme.colors.primary,
    verticalAlign: "text-top",
    display: "block"
  };

  return (
    <Button variant={"toggle"} className={active ? "active" : ""} sx={{
      boxShadow: [Theme.shadows.small, Theme.shadows.window],
      m: 1,
      px: [2, 3],
      ...sx
    }} {...props}>
      <Flex alignItems={"center"}>
        <Box marginRight={[1, 2]}>
          <Icon style={iconStyle} />
        </Box>
        <Box>{children}</Box>
      </Flex>
    </Button>
  );
};

ToggleButton.propTypes = {
  Icon: PropTypes.elementType,
  children: PropTypes.element,
  active: PropTypes.bool,
  sx: PropTypes.object
}

export default ToggleButton;
