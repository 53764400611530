import React from "react";
import { Link } from "./Link";
import { FaExternalLinkAlt } from "react-icons/fa";
import { Theme } from "themes/Theme";

export const ExternalLink = ({children, ...props}) => {
  return (
    <Link target="_blank" rel="noopener noreferrer" {...props}>
      {children} <FaExternalLinkAlt style={{ color: Theme.colors.primary }} />
    </Link>
  );
}

export default ExternalLink;
