import React from "react";
import {Button} from "rebass";
import {greyExitButton} from "icons";

export default function ClearSearch(props) {
  return (<Button id="clearSearch" variant="clear" sx={{
      display: "block",
      backgroundImage: `url('${greyExitButton}')`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      backgroundSize: "14px",
      width: 23,
      padding: "11px 3px",
      position: "relative",
      top: "9px",
      right: "32px",
      cursor: "pointer",
      ":hover" : {
        backgroundColor: "primary25",
        color: "white"
      }
    }} onClick={props.handleClick}/>);
}
