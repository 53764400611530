import React from "react";
import DetailsCard from "./DetailsCard";
import OutOfBoundsCard from "components/map/OutOfBoundsCard";
import SearchResultsCard from "./SearchResultsCard";
import { Box } from "rebass";

export default function SchoolInfo(props) {
  const {
    searchInfo,
    setActiveSchoolId,
    schoolInfo,
    inBounds,
    google,
    clearAllSearchParams,
    schoolGeometries,
    geometryCount,
    setAnimateMarker,
    nearbySchools
  } = props;

  if (!inBounds.current && searchInfo.lat) {
    return (
      <OutOfBoundsCard clearAllSearchParams={clearAllSearchParams} />
    );
  }

  if (schoolInfo) {
    return (
      <Box className="google-api-wrapped-100h" sx={{
        flexGrow: 1,
        height: "100%",
        position: "relative",
      }}>
        <DetailsCard
          google={google}
          schoolInfo={schoolInfo}
          searchInfo={searchInfo}
        />
      </Box>
    );
  }

  return (
    <SearchResultsCard
      schoolGeometries={schoolGeometries}
      geometryCount={geometryCount}
      setActiveSchoolId={setActiveSchoolId}
      setAnimateMarker={setAnimateMarker}
      searchInfo={searchInfo}
      nearbySchools={nearbySchools}
    />
  );
}
