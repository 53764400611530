const ga = code => {
  if (window && document && code) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://www.googletagmanager.com/gtag/js?id=${code}`;
    document.body.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', code);
  }
}

export { ga };
export default ga;
