import React from "react";
import { Button as RebassButton } from "rebass";

export const Button = ({sx = {}, ...props}) => {
  return (
    <RebassButton variant={"flat"} sx={{cursor: "pointer", ...sx}} {...props} />
  );
}

export default Button;
