import store from "store";
import expirationPlugin from "store/plugins/expire";

store.addPlugin(expirationPlugin);

const ONE_WEEK = 1000 * 60 * 60 * 24 * 7;
const expireIn = (value) => new Date().getTime() + value;

const SCHOOL_PICKER_LIST = "SCHOOL_PICKER_LIST";
const schoolPickerList = {
  get: () => store.get(SCHOOL_PICKER_LIST),
  set: (value) => store.set(SCHOOL_PICKER_LIST, [...value], expireIn(ONE_WEEK))
};

const GUIDE_WINDOW_HIDE = "GUIDE_WINDOW_HIDE";
const guideWindowHide = {
  get: () => store.get(GUIDE_WINDOW_HIDE),
  set: (value) => store.set(GUIDE_WINDOW_HIDE, value)
};

export {
  store,
  schoolPickerList,
  guideWindowHide
};

export default store;
