import React, { useContext } from "react";
import { Box, Button, Flex, Image } from "rebass";
import { Link } from "./overlay/Link";
import WindowToggleContext from "contexts/WindowToggleContext";
import { withTranslation, Trans } from "react-i18next";

import Window from "./window/Window";
import WindowHeader from "./window/WindowHeader";
import WindowContent from "./window/WindowContent";
import { logoOnLightReducedEmphasis as logo } from "icons";
import EnrollTulsaLink from "./EnrollTulsaLink";

export const WINDOW_NAME = "GUIDE_WINDOW";

const CONTACT_EMAIL = "enroll@tulsaschools.org"
const CONTACT_SUBJECT = "FindASchool Question"

const leftAlignedList = {
  // https://stackoverflow.com/a/59989499/1596013
  "& ol": {
    listStyle: "none",
    counterReset: "step-counter",
    paddingInlineStart: "1.5em"
  },
  "& ol > li": {
    counterIncrement: "step-counter",
    marginBottom: "0.5em"
  },
  "& ol > li:before": {
    content: "counter(step-counter)'.'",
    display: "inline-block",
    position: "absolute",
    marginLeft: "-1.5em"
  }
}

const ContactLink = () => (
  <Link href={encodeURI(`mailto:${CONTACT_EMAIL}?subject=${CONTACT_SUBJECT}`)}>
    {CONTACT_EMAIL}
  </Link>
)

export const GuideWindow = withTranslation()((props) => {
  const {visibleWindow, closeVisibleWindow} = useContext(WindowToggleContext);

  const hideGuide = (event) => {
    event.preventDefault();
    closeVisibleWindow();
  };

  if (visibleWindow !== WINDOW_NAME) {
    return null;
  };

  return (
    <Window {...props}>
      <WindowHeader>
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <Box>
            <Image height={"50px"} alt="Tulsa Schools Logo" src={logo} />
          </Box>
          <Box justifyContent={"flex-end"} flex={"none"}>
            <Link mx={2} fontSize={1} href={"#"} onClick={hideGuide}>
              <Trans>
                Hide this Guide
              </Trans>
            </Link>
          </Box>
        </Flex>
      </WindowHeader>
      <WindowContent sx={leftAlignedList}>
        <ol>
          <li>
            <Trans>
              <b>Browse schools</b> in your neighborhood or in the district.
            </Trans>
          </li>
          <li>
            <Trans>
              Save your preferred schools to <b>My List</b>.
            </Trans>
          </li>
          <li>
            <Trans>
              Send <b>My List</b> to yourself (or your family members) for reference during online enrollment.
            </Trans>
          </li>
        </ol>

        <p>&nbsp;</p>

        <p>
          <Trans>
            For up-to-date enrollment info and guidelines, visit <EnrollTulsaLink />.
          </Trans>
        </p>

        <p>
          <Trans>
            Questions? Contact <ContactLink />
          </Trans>
        </p>
      </WindowContent>

      <WindowContent sx={{
        display: ["block", "none"],
        textAlign: "center"
      }}>
        <Button variant={"primary"} onClick={hideGuide}>
          <Trans>
            Hide this Guide
          </Trans>
        </Button>
      </WindowContent>
    </Window>
  );
});

export default GuideWindow;
