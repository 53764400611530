import React, { useContext } from "react";
import { withTranslation, Trans } from "react-i18next";
import WindowToggleContext from "contexts/WindowToggleContext";
import ToggleButton from "./window/ToggleButton";
import { FaBookmark } from "react-icons/fa";

import { WINDOW_NAME } from "./MyListWindow";

export const MyListToggle = withTranslation()((props) => {
  const {visibleWindow, openVisibleWindow, closeVisibleWindow} = useContext(WindowToggleContext);

  const active = visibleWindow === WINDOW_NAME;

  function onClick() {
    if (active) {
      closeVisibleWindow()
    } else {
      openVisibleWindow(WINDOW_NAME)
    }
  };

  return (
    <ToggleButton Icon={FaBookmark} active={active} onClick={onClick} {...props}>
      <Trans>
        My List
      </Trans>
    </ToggleButton>
  );
});

export default MyListToggle;
