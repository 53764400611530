import React from "react";
import { Link } from "./overlay/Link";
import { useTranslation } from 'react-i18next'

export const PrintableContent = (props) => {
  const { schools } = props;
  const { t } = useTranslation()

  return (
    <div id="printable-content">
      <h2>My List of TPS Schools</h2>
      <div id="school-info">
        You may apply to up to 6 schools, but applying to 6 schools is not required. School details are listed below.
        {schools.map((school, index) => (
          <div key={index}>
            <h4>{index + 1}: {school.name}</h4>
            <div id="school-type">
              {school.is_neighborhood && t('desc_neighborhood')}
              {school.is_charter && t('desc_charter')}
              {school.is_magnet && t('desc_magnet')}
              {school.is_alternative && t('desc_alternative')}
            </div>
            Website: {school.website}
          </div>
        ))}
      </div>
      <hr />
      <div id="links">
        <h4>Enrollment FAQ and Next Steps</h4>
        <Link fontSize={1} href={"https://www.tulsaschools.org/enrollment/enrolltulsa"}>https://www.tulsaschools.org/enrollment/enrolltulsa</Link>
        <h4>Find a School</h4>
        <Link fontSize={1} href={"https://findaschool.tulsaschools.org"}>https://findaschool.tulsaschools.org</Link>
      </div>
    </div>
  )
}

export default PrintableContent;
