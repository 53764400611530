import React, {createContext, useState, useRef} from "react";

export const SearchInfoContext = createContext();

export const SearchInfoProvider = props => {
  const [searchInfo, setSearchInfo] = useState({lat: null, lng: null, address: ""});
  const searchInputRef = useRef(null);

  //Default searched address is an empty string
  const searchedAddressRef = useRef("");

  const clearSearch = () => {
    setSearchInfo({lat: null, lng: null, address: ""});
    searchInputRef.current.value = "";
    searchedAddressRef.current = "";
  };

  //Grabbing the full text of the search bar upon search execution
  if (searchInputRef.current) {
    searchedAddressRef.current = searchInputRef.current.value || "";
  }

  return (
    <SearchInfoContext.Provider value={{
      searchInfo,
      setSearchInfo,
      clearSearch,
      searchInputRef,
      searchedAddressRef
    }}>
      {props.children}
    </SearchInfoContext.Provider>
  );
};

export default SearchInfoProvider;
