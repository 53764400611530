import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import SchoolPickerContext from "contexts/SchoolPickerContext";
import { Theme } from 'themes/Theme'
import { Button, Flex } from "rebass";
import { FaRegBookmark, FaBookmark } from "react-icons/fa";
import WindowToggleContext from "contexts/WindowToggleContext";
import { WINDOW_NAME } from "../map/MyListWindow";

const SaveIcon = () => <FaRegBookmark style={{marginRight: "4px", color: Theme.colors.primary}} />;
const SavedIcon = () => <FaBookmark style={{marginRight: "4px", color: Theme.colors.primary}} />;

const BaseButton = ({primary = false, sx = {}, children, ...props}) => {
  const style = {
    backgroundColor: primary ? "primary" : "black40",
    ...sx
  };

  return (
    <Button variant={"full"} sx={style} {...props}>
      <Flex alignItems={"center"} justifyContent={"center"}>
        {children}
      </Flex>
    </Button>
  );
}

const SearchResultButton = ({gray = false, sx = {}, children, ...props}) => {
  const style = {
    backgroundColor: gray ? "black20" : "white",
    width: "100%",
    color: "primary",
    border: "none",
    ...sx
  };

  return (
    <BaseButton variant={"flat"} sx={style} {...props}>
      {children}
    </BaseButton>
  );
}

export const SchoolPickerButton = ({schoolId, searchResult = false}) => {
  const { t } = useTranslation();
  const schoolPicker = useContext(SchoolPickerContext);

  const Button = searchResult ? SearchResultButton : BaseButton;
  const {visibleWindow, openVisibleWindow} = useContext(WindowToggleContext);
  const active = visibleWindow === WINDOW_NAME;

  const openList = () => {
    if (!active) {
      openVisibleWindow(WINDOW_NAME)
    }
  }
  const handleClick = (action) => {
    return (event) => {
      event.stopPropagation();
      switch (action) {
        case "add":
          return schoolPicker.add(schoolId);
        case "remove":
          return schoolPicker.remove(schoolId);
        case "show":
          return openList();
        default:
          throw new Error(`Invalid action: ${action}`);
      }
    };
  }

  if (schoolPicker.has(schoolId)) {
    return (
      <Button data-testid={"remove_from_my_list_button"} onClick={handleClick("remove")}>
        {searchResult && (<SavedIcon />)}
        {t(searchResult ? "saved" : "remove_from_my_list")}
      </Button>
    );
  }

  if (schoolPicker.isFull()) {
    return (
      <Button data-testid={"my_list_is_full_button"} gray onClick={handleClick("show")}>
        {t(searchResult ? "list_full" : "my_list_is_full")}
      </Button>
    );
  }

  return (
    <Button data-testid={"add_to_my_list_button"} primary onClick={handleClick("add")}>
      {searchResult && (<SaveIcon />)}
      {t(searchResult ? "save" : "add_to_my_list")}
    </Button>
  );
};

export default SchoolPickerButton;
