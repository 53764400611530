import React from "react";
import {Box, Text} from "rebass";
import {Theme} from "themes/Theme";

const Toggle = props => (<Box sx={{
    display: "inline-flex",
    marginLeft: 'auto',
    alignItems: 'center',
    // Switch container
    ".switch" : {
      position: "relative",
      display: "inline-block",
      width: "56px",
      height: 6
    },

    // Hide browser input button
    ".switch input" : {
      display: "none"
    },

    // Sliding grey checkbox replacement block
    ".slider" : {
      position: "absolute",
      cursor: "pointer",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      backgroundColor: "#E5E5E5",
      WebkitTransition: ".4s",
      transition: ".4s",
      borderRadius: "10px",
      placeholder: {
        text: "blah"
      }
    },

    // creating an empty text bg-color container at the beginning of Slider
    ".slider:before" : {
      content: '""',
      position: "absolute",
      height: 5,
      width: "25px",
      left: "4px",
      bottom: "4px",
      backgroundColor: "primary",
      WebkitTransition: ".4s",
      transition: ".4s",
      borderRadius: `${Theme.radii.default + "px"}`
    },

    // adjusts the position of the objects created at the start of the Slider block if the Slider is next to a checked checkbox.
    "input:checked + .slider:before" : {
      WebkitTransform: "translateX(22px)",
      MsTransform: "translateX(22px)",
      transform: "translateX(22px)"
    },

    ".slider .en" : {
      position: "absolute",
      left: "8px",
      bottom: "13px",
      color: "white"
    },

    ".slider .es" : {
      position: "absolute",
      right: "9px",
      bottom: "13px",
      color: "primary"
    },

    "input:checked + .slider .es" : {
      color: "white"
    },
    "input:checked + .slider .en" : {
      color: "primary"
    }
  }}>
  <label className="switch">
    <input type="checkbox" {...props}/>
    <span className="slider">
      <Text variant="thickSmall" className="en" sx={{
          display: "inline-block",
          position: "absolute",
          fontWeight: "semiBold"
        }}>
        en
      </Text>
      <Text variant="thickSmall" className="es" sx={{
          display: "inline-block",
          position: "absolute",
          fontWeight: "semiBold"
        }}>
        es
      </Text>
    </span>
  </label>
</Box>);

export default Toggle
