import DragTypes from "DragTypes";
import PrintableContent from "./PrintableContent";
import React, { useContext, useEffect, useRef, useState } from "react";
import SchoolPickerContext from "contexts/SchoolPickerContext";
import SchoolDataContext from "contexts/SchoolDataContext";
import update from "immutability-helper";
import WindowToggleContext from "contexts/WindowToggleContext";
import { Box, Flex, Text } from "rebass";
import { Link } from "./overlay/Link";
import { ExternalLink } from "./overlay/ExternalLink";
import { useDrop } from "react-dnd";
import { MyListItem } from "./MyListItem";
import { MyListNumber } from "./MyListNumber";
import { MyListLabel } from "./MyListLabel";
import Window from "./window/Window";
import WindowHeader from "./window/WindowHeader";
import WindowContent from "./window/WindowContent";
import { FaPrint, FaBookmark } from "react-icons/fa"
import { withTranslation, Trans } from "react-i18next";
import { Theme } from "themes/Theme";
import EnrollTulsaLink from "./EnrollTulsaLink";
import EmailForm from './EmailForm'

export const WINDOW_NAME = "MY_LIST_WINDOW";

const filterSchools = (schoolData, schoolPicker) => {
  const filtered = Object.values(schoolData.schools).reduce((prev, curr) => {
    if (schoolPicker.has(curr.id)) {
      prev[curr.id] = curr;
    }

    return prev;
  }, {});

  // NOTE: map the school picker values rather than the filtered schools to preserve order
  return [...schoolPicker].map((id) => filtered[id]).filter(school => school);
};

const EnrollTulsaFaqLink = withTranslation()(() => (
  <ExternalLink href="https://www.tulsaschools.org/about/frequently-asked-questions#enroll">
    <Trans>Enroll Tulsa</Trans> FAQ
  </ExternalLink>
))

export const MyListWindow = withTranslation()(({t, ...props}) => {
  const { setActiveSchoolId } = props;
  const schoolPicker = useContext(SchoolPickerContext);
  const schoolData = useContext(SchoolDataContext);
  const {visibleWindow, closeVisibleWindow} = useContext(WindowToggleContext);

  const [schools, setSchools] = useState([]);
  const [triggerSchoolPickerUpdate, setTriggerSchoolPickerUpdate] = useState(false);
  const printableContentRef = useRef();

  const [, drop] = useDrop({ accept: DragTypes.SCHOOL_PICKER });
  const moveItem = (id, atIndex) => {
    const { index, school } = findItem(id);

    setSchools(update(schools, {
      $splice: [
          [index, 1],
          [atIndex, 0, school],
      ],
    }));
  };
  const findItem = (id) => {
    const school = schools.find((school) => school.id === id);
    const index = schools.indexOf(school);

    return { index, school };
  };
  const endMove = (id) => {
    setTriggerSchoolPickerUpdate(true)
  };
  const moveProps = { findItem, moveItem, endMove };

  const onClickPrint = (event) => {
    event.preventDefault();
    const winPrint = window.open();
    winPrint.document.write(printableContentRef.current.innerHTML);
    winPrint.document.title = t("My List of TPS Schools")
    winPrint.document.close();
    winPrint.print();
  };

  const onClickHide = (event) => {
    event.preventDefault();
    closeVisibleWindow();
  };

  const onClickSchool = (id) => {
    return (event) => {
      event.preventDefault();
      setActiveSchoolId(id);
    }
  }

  const onClickRemove = (id) => {
    return (event) => {
      event.preventDefault();
      schoolPicker.remove(id);
    }
  }

  useEffect(() => {
    setSchools(filterSchools(schoolData, schoolPicker))
  }, [schoolData, schoolPicker]);

  useEffect(() => {
    if (triggerSchoolPickerUpdate) {
      setTriggerSchoolPickerUpdate(false)
      schoolPicker.replace(schools.map((school) => school.id))
    }
  }, [schools, triggerSchoolPickerUpdate, schoolPicker])

  if (visibleWindow !== WINDOW_NAME) {
    return null;
  };

  return (
    <Window {...props}>
      <div style={{ display: "none" }} ref={printableContentRef}>
        <PrintableContent schools={schools} />
      </div>

      <WindowHeader>
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <Box sx={{
            textTransform: "uppercase"
          }}>
            <Text variant={"h1"}>
              <Flex alignItems={"center"}>
                <FaBookmark color={Theme.colors.primary} />
                <Box ml={1}>
                  <Trans>
                    My List
                  </Trans>
                </Box>
              </Flex>
            </Text>
          </Box>
          <Flex justifyContent={"flex-end"}>
            <Link mx={2} fontSize={1} href={"#"} onClick={onClickPrint}>
              <Flex alignItems={"center"}>
                <FaPrint color={"primary"} />
                <Box ml={1}>
                  <Trans>
                    Print
                  </Trans>
                </Box>
              </Flex>
            </Link>
            <Link mx={2} fontSize={1} href={"#"} onClick={onClickHide}>
              <Trans>
                Hide
              </Trans>
            </Link>
          </Flex>
        </Flex>
      </WindowHeader>

      <WindowContent>
        <Box ref={drop}>
          {schools.map((school, index) => (
            <MyListItem
              key={school.id} id={school.id} {...moveProps}
              alignItems={"center"} p={1} sx={{
                backgroundColor: "white"
              }}
            >
              <MyListNumber number={findItem(school.id).index + 1} />
              <MyListLabel school={school}
                onClickSchool={onClickSchool(school.id)}
                onClickRemove={onClickRemove(school.id)}
              />
            </MyListItem>
          ))}
          {schoolPicker.limit && Array(schoolPicker.limit - schools.length).fill().map((_, index) => (
            <Flex
              key={schools.length + index}
              alignItems={"center"} p={1} sx={{
                backgroundColor: "white"
              }}
            >
              <MyListNumber number={schools.length + index + 1} />
              <MyListLabel />
            </Flex>
          ))}
        </Box>
      </WindowContent>

      <Box sx={{
        opacity: 0.5,
        borderTop: `1px solid ${Theme.colors.black95}`
      }} />

      <WindowContent>
        <Text variant={"small"}>
          <Trans>
            <EnrollTulsaLink />
          </Trans>
        </Text>

        <Box mt={2}>
          <EmailForm schools={schools} />
        </Box>

        {(schoolPicker.limit > 0) && (
          <Box color={"black95"}>
            <Text variant={"small"}>
              <Trans values={{limit: schoolPicker.limit}}>
                You can apply to up to {'{{limit}}'}, but all {'{{limit}}'} are not required.
              </Trans>
            </Text>
            <Text variant={"small"}>
              <Trans>
                For more info, visit <EnrollTulsaFaqLink />
              </Trans>
            </Text>
          </Box>
        )}
      </WindowContent>
    </Window>
  );
});

export default MyListWindow;
