import { Flex, Box, Image } from "rebass";
import { logoOnDark as logo } from "icons";
import { logoOnDark_mobile_wide as logo_mobile_wide } from "icons";
import { types } from "reducers/school-type-toggle-reducer";
import { gradeLevelTypes } from "reducers/grade-level-toggle-reducer"
import "remove-focus-outline";
import FilterDisplay from "./FilterDisplay";
import React, { useState, useEffect, useContext } from "react";
import SearchBar from "./SearchBar";
import SearchFilters from "./SearchFilters";
import { mapLocalizableObjectsToOptions } from "utils";
import { useTranslation } from "react-i18next";
import LanguageToggle from "./LanguageToggle";
import SchoolDataContext from "contexts/SchoolDataContext";
import * as setOperations from "utils/set-operations";
import { filterSchoolsByType } from "utils/school-helpers";
import { useHistory } from "react-router-dom";

const typeOptionMap = {
  "neighborhood": types.TOGGLE_NEIGHBORHOOD,
  "magnet": types.TOGGLE_MAGNET,
  "charter": types.TOGGLE_CHARTER,
  "alternative": types.TOGGLE_ALTERNATIVE,
};

const gradeLevelOptionsMap = {
  "elementary": gradeLevelTypes.TOGGLE_ELEMENTARY,
  "middle": gradeLevelTypes.TOGGLE_MIDDLE,
  "high": gradeLevelTypes.TOGGLE_HIGH,
  "early_childhood": gradeLevelTypes.TOGGLE_EARLY_CHILDHOOD,
};

const activitiesForSchools = (schools, activities) => {
  const activityMap = new Map();

  Object.values(schools).forEach((school) => {
    school.activity_ids.forEach((activity_id) => {
      const activity = activities[activity_id];
      if (activity) {
        activityMap.set(activity.id, activity);
      }
    })
  });

  return [...activityMap.values()];
}

export default function NavBar(props) {
  const { activeSchoolId, clearAllSearchParams, gradeLevelDispatch, schoolTypeDispatch, setActivityIds } = props;
  const schoolData = useContext(SchoolDataContext);

  const [activityOptions, setActivityOptions] = useState([]);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [selectedGradeLevels, setSelectedGradeLevels] = useState([]);
  const [selectedSchoolTypes, setSelectedSchoolTypes] = useState([]);
  const { t } = useTranslation();
  const history = useHistory();

  const applyFilters = selectedOptions => {
    let activityIds = selectedOptions.map(activity => activity.value);
    setActivityIds(activityIds);
  };

  const handleFilterChange = options => {
    if (options && options.length) {
      setSelectedActivities(options);
      applyFilters(options);
    } else {
      setSelectedActivities([]);
      applyFilters([], []);
    }
  };

  const handleGradeLevelChange = (options, previousOptions) => {
    if (options.length) {
      setOperations.symmetricDifference(
        new Set(options),
        new Set(previousOptions)
      ).forEach((option) => {
        const type = gradeLevelOptionsMap[option.value];
        if (type) gradeLevelDispatch({ type });
      });
    } else {
      gradeLevelDispatch({ type: gradeLevelTypes.TOGGLE_ALL });
    }

    setSelectedGradeLevels(options);
  };

  const handleSchoolTypeChange = (options, previousOptions) => {
    if (options.length) {
      setOperations.symmetricDifference(
        new Set(options),
        new Set(previousOptions)
      ).forEach((option) => {
        const type = typeOptionMap[option.value];
        if (type) schoolTypeDispatch({ type });
      });
    } else {
      schoolTypeDispatch({ type: types.TOGGLE_ALL });
    }

    setSelectedSchoolTypes(options);
  };

  useEffect(() => {
    const schools = filterSchoolsByType(
      Object.values(schoolData.schools),
      selectedSchoolTypes.map(({ value }) => value)
    )

    const programOptions = mapLocalizableObjectsToOptions(
      activitiesForSchools(schools, schoolData.activities)
    );

    setActivityOptions([
      {
        label: t("Programs"),
        options: programOptions
      }
    ])
  }, [t, selectedSchoolTypes, schoolData]);

  const spacing = 2;

  return (
    <Box bg="black100" p={spacing}>
      <Flex flexWrap={["wrap", "wrap", "nowrap"]} alignItems={"center"}>
        <Box p={spacing} flex={"none"} width={["100%", "100%", "auto"]} onClick={() => history.push('/')} sx={{ cursor: 'pointer' }}>
          <Image display={["block", "block", "none"]} height={"20px"} alt="Tulsa Schools Logo" id="tpsLogo" src={logo_mobile_wide} />
          <Image display={["none", "none", "block"]} height={"40px"} alt="Tulsa Schools Logo" id="tpsLogo" src={logo} />
        </Box>

        <SearchBar p={spacing} flexGrow={1} clearAllSearchParams={clearAllSearchParams} />

        <Box p={spacing} flex={"none"} order={["initial", "initial", 4]}>
          <LanguageToggle />
        </Box>

        {!activeSchoolId && (
          <Box p={spacing} order={["initial", "initial", 3]} flexShrink={[0, 0, 1]} width={["100%", "100%", "auto"]}>
            <SearchFilters
              activityOptions={activityOptions}
              selectedActivities={selectedActivities}
              selectedGradeLevels={selectedGradeLevels}
              selectedSchoolTypes={selectedSchoolTypes}
              handleFilterChange={handleFilterChange}
              handleGradeLevelChange={handleGradeLevelChange}
              handleSchoolTypeChange={handleSchoolTypeChange}
            />
          </Box>
        )}
      </Flex>

      {!activeSchoolId && (selectedActivities.length || selectedGradeLevels.length || selectedSchoolTypes.length) ? (
        <Box m={spacing}>
          <FilterDisplay
            handleFilterChange={handleFilterChange}
            handleGradeLevelChange={handleGradeLevelChange}
            handleSchoolTypeChange={handleSchoolTypeChange}
            selectedActivities={selectedActivities}
            selectedGradeLevels={selectedGradeLevels}
            selectedSchoolTypes={selectedSchoolTypes}
          />
        </Box>
      ) : null}
    </Box>
  );
}
