import React from "react";
import { Box, Flex } from "rebass";
import GoogleMap from "./GoogleMap";
import GuideToggle from "./GuideToggle";
import MyListWindow from "./MyListWindow";
import GuideWindow from "./GuideWindow";
import MyListToggle from "./MyListToggle";

export default function Embed({setActiveSchoolId, ...props}) {
  return (
    <Box className={"box"} sx={{
      width: "100%",
      height: "100%",
      position: "absolute"
    }}>
      <GoogleMap setActiveSchoolId={setActiveSchoolId} {...props} />

      <Box sx={{
        padding: 2,
        maxHeight: "100%",
        maxWidth: "100%",
        top: 0,
        right: 0,
        position: "absolute",
      }}>
        <Flex justifyContent={"flex-end"}>
          <GuideToggle />
          <MyListToggle />
        </Flex>
        <Flex justifyContent={"flex-end"}>
          <GuideWindow />
          <MyListWindow width={"400px"} setActiveSchoolId={setActiveSchoolId} />
        </Flex>
      </Box>
    </Box>
  );
}
