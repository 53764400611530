import React from 'react'
import { Box } from 'rebass'
import { Theme } from 'themes/Theme'
import defaultExterior from 'icons/default_exterior_background.svg'

export function GridSection({ gridArea, sx, ...props }) {
  return (
    <Box
      className="grid-section"
      sx={{
        borderTop: `1px solid ${Theme.colors.black20}`,
        borderRight: `1px solid ${Theme.colors.black20}`,
        marginTop: '-1px',
        marginRight: '-1px',
        padding: 4,
        gridArea,
        ...sx,
      }}
      {...props}
    >
      {props.children}
    </Box>
  )
}

export function GridSectionImage({ photoUrl, gridArea, sx, ...props }) {
  return (
    <GridSection
      sx={{
        bg: 'black20',
        backgroundImage: `url(${photoUrl || defaultExterior})`,
        backgroundPosition: '50% 50%',
        backgroundSize: 'cover',
        position: 'relative',
        height: '200px',
        borderColor: `hsla(211.33333333333331, 76.27%, 53.73%, 0.0)`,
        gridArea,
        ...sx,
      }}
      {...props}
    >
      {props.children}
    </GridSection>
  )
}

export function InlineBox({ sx, ...props }) {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'inline-flex',
        ...sx,
      }}
      {...props}
    >
      {props.children}
    </Box>
  )
}

export function Grid({ sx, ...props }) {
  return (
    <Box
      sx={{
        // CSS Grid Setup
        display: 'grid',
        gridAutoColumns: '1fr',
        gridColumnGap: 0,
        gridRowGap: 0,
        gridTemplateAreas:
          '"Area Area" "Area-2 Area-2" "Area-3 Area-3"  "Area-4 Area-4" "Area-5 Area-5"',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: 'auto auto auto auto auto',
        boxShadow: 'small',
        ...sx,
      }}
      {...props}
    >
      {props.children}
    </Box>
  )
}
