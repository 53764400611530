import React from "react";
import {Flex} from "rebass";
import Tag from "./Tag";
import {useTranslation} from "react-i18next";

export default function FilterDisplay(props) {
  const {handleFilterChange, handleGradeLevelChange, handleSchoolTypeChange, selectedActivities, selectedGradeLevels, selectedSchoolTypes} = props;
  const {t} = useTranslation();

  const onClickGradeLevelTag = gradeLevel => {
    let gradeLevels = selectedGradeLevels.filter(g => t(g.value) !== t(gradeLevel.value));
    handleGradeLevelChange(gradeLevels, selectedSchoolTypes);
  }

  const onClickSchoolTypeTag = schoolType => {
    let schoolTypes = selectedSchoolTypes.filter(s => t(s.value) !== t(schoolType.value));
    handleSchoolTypeChange(schoolTypes, selectedSchoolTypes);
  }

  const onClickActivitiesTag = activity => {
    let activities = selectedActivities.filter(a => t(a.label) !== t(activity.label));
    handleFilterChange(activities);
  }

  return (
    <Flex sx={{
      overflow: "hidden",
      ":last-child" : {
        maskImage: "linear-gradient(to right, #ffffff 75%, rgba(255, 255, 255, 0) 100%)"
      }
    }}>
      {selectedGradeLevels.map(gradeLevel => (
        <Tag key={gradeLevel.value} onClick={() => onClickGradeLevelTag(gradeLevel)}>
          {` ${t(gradeLevel.value)}`}
        </Tag>
      ))}
      {selectedSchoolTypes.map(schoolType => (
        <Tag key={schoolType.value} onClick={() => onClickSchoolTypeTag(schoolType)}>
          {` ${t(schoolType.value)}`}
        </Tag>
      ))}
      {selectedActivities.map(activity => (
        <Tag key={activity.value} onClick={() => onClickActivitiesTag(activity)}>
          {` ${t(activity.label)}`}
        </Tag>
      ))}
    </Flex>
  );
}
