import {Flex, Box} from "rebass";
import {useTranslation} from "react-i18next";
import MultiSelect, {customDropdownButtonLabel} from "./MultiSelect";
import React from "react";
import ClearFiltersButton from "./ClearFiltersButton";

export default function SearchFilters(props) {
  const {
    activityOptions,
    selectedActivities,
    selectedGradeLevels,
    selectedSchoolTypes,
    handleFilterChange,
    handleGradeLevelChange,
    handleSchoolTypeChange
  } = props;

  const resetFilters = () => {
    handleFilterChange();
    handleGradeLevelChange([], []);
    handleSchoolTypeChange([], []);
  };

  const { t } = useTranslation();

  const gradeLevelOptions = [
    {
      label: t("grade_levels"),
      options: [
        {
          label: t("elementary"),
          value: "elementary"
        }, {
          label: t("middle"),
          value: "middle"
        }, {
          label: t("high"),
          value: "high"
        }, {
          label: t("early_childhood"),
          value: "early_childhood"
        }
      ],
      disabled: true
    }
  ]

  const schoolTypeOptions = [
    {
      label: t("school_types"),
      options: [
        {
          label: t("neighborhood"),
          value: "neighborhood"
        }, {
          label: t("magnet"),
          value: "magnet"
        }, {
          label: t("charter"),
          value: "charter"
        }, {
          label: t("alternative"),
          value: "alternative"
        }
      ],
      disabled: true
    }
  ];

  const filtersAreSelected = selectedGradeLevels.length + selectedSchoolTypes.length + selectedActivities.length > 0;

  const onChangeGradeLevel = selectedOptions => {
    handleGradeLevelChange(selectedOptions, selectedGradeLevels)
  }

  const onChangeSchoolType = selectedOptions => {
    handleSchoolTypeChange(selectedOptions, selectedSchoolTypes)
  }

  const onChangeSelectedOptions = selectedOptions => {
    handleFilterChange(selectedOptions)
  }

  return (
    <Flex>
      <Box data-testid="multiselect-grade-level-filter">
        <MultiSelect
          id="grade-level-filter"
          placeholderButtonLabel={t("grade_levels")}
          getDropdownButtonLabel={customDropdownButtonLabel(length => t("grade_levels_selected", {count: length}))}
          value={selectedGradeLevels}
          options={gradeLevelOptions}
          maxMenuHeight={450}
          styles={{
            option: () => ({color: "#000000"}),
            control: () => ({display: "none"})
          }}
          onChange={onChangeGradeLevel}
          closeMenuOnSelect={false}
          isMulti="isMulti"
        />
      </Box>
      <Box ml={1} data-testid="multiselect-school-type-filter">
        <MultiSelect
          id="school-type-filter"
          placeholderButtonLabel={t("select_school_types")}
          getDropdownButtonLabel={customDropdownButtonLabel(length => t("school_types_selected", {count: length}))}
          value={selectedSchoolTypes}
          options={schoolTypeOptions}
          maxMenuHeight={450}
          styles={{
            option: () => ({color: "#000000"}),
            control: () => ({display: "none"})
          }}
          onChange={onChangeSchoolType}
          closeMenuOnSelect={false}
          isMulti="isMulti"
        />
      </Box>

      {activityOptions && (
        <Box ml={1} data-testid="multiselect-activities-filter">
          <MultiSelect
            id="activities-filter"
            placeholderButtonLabel={t("programs")}
            getDropdownButtonLabel={customDropdownButtonLabel(length => t("programs_selected", {count: length}))}
            value={selectedActivities}
            options={activityOptions}
            styles={{
              option: () => ({color: "#000000"})
            }}
            onChange={onChangeSelectedOptions}
            closeMenuOnSelect={false}
            isMulti="isMulti"
            rightAligned={true}
          />
        </Box>
      )}

      {filtersAreSelected && <ClearFiltersButton onClick={resetFilters} ml={1} />}
    </Flex>
  );
}
