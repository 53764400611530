import React from "react";
import PropTypes from "prop-types";
import { Flex } from "rebass";
import { useDrag, useDrop } from "react-dnd";
import DragTypes from "DragTypes";

export const MyListItem = ({
  id,
  findItem,
  moveItem,
  beginMove = () => {},
  endMove = () => {},
  ...props
}) => {
  const originalIndex = findItem(id).index;

  const [{ isDragging }, drag] = useDrag({
    item: { type: DragTypes.SCHOOL_PICKER, id, originalIndex },
    collect: (monitor) => {
      const isDragging = monitor.isDragging();
      return { isDragging };
    },
    begin: (monitor) => {
      beginMove(id);
    },
    end: (dropResult, monitor) => {
      const { id: droppedId, originalIndex } = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        moveItem(droppedId, originalIndex);
      }
      endMove(id);
    },
  });

  const [, drop] = useDrop({
    accept: DragTypes.SCHOOL_PICKER,
    canDrop: () => false,
    hover({ id: draggedId }) {
      if (draggedId !== id) {
        const { index: overIndex } = findItem(id);

        moveItem(draggedId, overIndex);
      }
    },
  });

  const opacity = isDragging ? 0 : 1;

  return (
    <Flex ref={(node) => drag(drop(node))} style={{ opacity }} {...props} />
  );
};

MyListItem.propTypes = {
  id: PropTypes.number.isRequired,
  findItem: PropTypes.func.isRequired,
  moveItem: PropTypes.func.isRequired,
  beginMove: PropTypes.func,
  endMove: PropTypes.func,
};

export default MyListItem;
