import React, { useEffect, useState } from "react";
import { guideWindowHide } from "api/storage";
import { WINDOW_NAME as GUIDE_WINDOW } from "components/map/GuideWindow"

export const WindowToggleContext = React.createContext();

export const WindowToggleProvider = ({limit, ...props}) => {
  // NOTE: if the storage value has not previously been set, it will be `undefined`.
  const guideWindowHideGet = guideWindowHide.get()
  const initVisibleWindow = guideWindowHideGet === undefined ? GUIDE_WINDOW : guideWindowHideGet;

  const [visibleWindow, setVisibleWindow] = useState(initVisibleWindow);

  const openVisibleWindow = (windowName) => {
    if (typeof windowName !== 'string') {
      throw new Error("windowName must be a string");
    }
    setVisibleWindow(windowName);
  }

  const closeVisibleWindow = () => {
    setVisibleWindow(null);
  }

  useEffect(() => {
    guideWindowHide.set(visibleWindow);
  }, [visibleWindow]);

  // Render
  return (
    <WindowToggleContext.Provider value={{
      visibleWindow,
      openVisibleWindow,
      closeVisibleWindow
    }}>
      {props.children}
    </WindowToggleContext.Provider>
  );
};

export default WindowToggleContext;
