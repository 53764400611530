import React from 'react'
import { Box } from 'rebass'
import { Theme } from 'themes/Theme'

export const WindowContent = ({children, sx = {}, ...props}) => {
  return (
    <Box sx={{
      "& > *": {
        m: `${Theme.space[2]}px !important`
      },
      backgroundColor: Theme.colors.white,
      maxWidth: Theme.maxReadingWidth,
      p: 3,
      ...sx
    }} {...props}>
      {children}
    </Box>
  )
}

export default WindowContent
