import i18n from "i18n";

export const mapLocalizableObjectsToOptions = (
  arrayOfObj,
  labelKey = "name",
  valKey = "id",
  sort = true
) => {
  let localizedList = arrayOfObj.map(obj => {
    let label = obj[labelKey][i18n.language] || obj[labelKey]['en'];
    let value = obj[valKey];

    return { label, value };
  });

  if (sort) {
    localizedList = localizedList.sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return +1;
      return 0;
    });
  }

  return localizedList;
};

export const isEventKeyBackspace = (event) => {
  if (event.key === "Backspace")
    return true;
  if (event.keyCode === 8)
    return true; // Compatibility

  return false;
};

export const isEventKeyEsc = (event) => {
  if (event.key === "Esc")
    return true;
  if (event.keyCode === 27)
    return true; // Compatibility

  return false;
};

export const keyReducer = (key) => {
  return (acc, cur) => {
    acc[cur[key]] = cur;
    return acc;
  };
};
