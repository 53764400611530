export class PickList extends Set {
  constructor(iterable, limit) {
    super(PickList.makeSet(iterable, limit));
    this.limit = limit;
  }

  isFull() {
    return this.limit && this.limit <= this.size;
  }

  add(value) {
    if (this.isFull()) {
      throw new Error(`PickList is full`);
    }

    return super.add(value);
  }

  replace(iterable) {
    const set = PickList.makeSet(iterable, this.limit);

    this.clear();

    for (const value of set) {
      this.add(value);
    }

    return this;
  }
}

PickList.makeSet = (iterable, limit) => {
  const set = new Set(iterable);

  if (limit && limit < set.size) {
    throw new Error(`PickList is limited to ${limit}`);
  }

  return set;
};

export default PickList;
