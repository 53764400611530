import React from "react";
import { Box, Flex, Image } from "rebass";
import { Link } from "./overlay/Link";
import MyListLabelHandle from "icons/MyListLabelHandle.svg";
import MyListLabelRemoveButton from "icons/MyListLabelRemoveButton.svg";

const RemoveButton = ({sx = {}, ...props}) => {
  return (
    <Flex sx={{
      cursor: "pointer",
      ...sx
    }} alignItems={"center"} {...props}>
      <Image src={MyListLabelRemoveButton} sx={{ display: "block", height: "1em" }} />
    </Flex>
  );
};

export const MyListLabel = ({
  school,
  onClickSchool = () => {},
  onClickRemove = () => {}
}) => {
  return (
    <Flex flexGrow={1} ml={1} sx={{
        backgroundColor: "white",
        cursor: school ? "move" : "default",
    }}>
      <Flex flexGrow={1} alignItems={"center"}>
        <Flex flexGrow={1} ml={2} alignItems={"stretch"} sx={{
          background: school ? "linear-gradient(0deg, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)), #2F85E3" : "white",
          border: school ? "1px solid rgba(22, 135, 203, 0.5)" : "1px solid rgba(150, 155, 158, 0.7)",
          borderRadius: "4px"
        }}>
          {school ? (
            <React.Fragment>
              <Link href="#" onClick={onClickSchool} m={2} mr={-2} sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                display: "block",
                flexGrow: 1,
              }}>
                {school.name}
              </Link>
              <RemoveButton onClick={onClickRemove} pl={3} pr={2} />
            </React.Fragment>
          ) : (
            <Box m={2}>&nbsp;</Box>
          )}
        </Flex>

        <Box ml={2} flex={"none"} width={"16px"}>
          <Image src={MyListLabelHandle} alt={"Drag & Drop Handle"} style={{
            display: "block",
            width: "100%"
          }} />
        </Box>
      </Flex>
    </Flex>
  );
};

export default MyListLabel;
