import {
  addressIcon,
  clockIcon,
  courseIcon,
  phoneIcon,
  travelIcon,
  schoolSize,
  webAddressIcon,
  schoolBuilding,
} from 'icons'
import { Box, Flex, Heading, Image, Link, Text } from 'rebass'
import {
  determineSchoolGradeLevels,
  determineSchoolType,
} from 'utils/school-helpers'
import { GoogleApiWrapper } from 'google-maps-react'
import { Theme } from 'themes/Theme'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import SchoolMap from './SchoolMap'
import SchoolPickerButton from './SchoolPickerButton'
import DetailsCardLink from './DetailsCardLink'
import DetailsCardLeadInfo from './DetailsCardLeadInfo'
import { Grid, GridSection, GridSectionImage, InlineBox } from './DetailsCardLayouts'
import DetailsCardList from './DetailsCardList'
import { schoolInfoType } from "utils/school-helpers";

function formatPhone(phone) {
  return [phone.slice(0, 3), phone.slice(3, 6), phone.slice(6, 10)].join('-')
}

function PhoneLink({ phone, ...props }) {
  const formattedPhone = formatPhone(phone)
  return (
    <Link href={`tel:${formatPhone}`} {...props}>
      {formattedPhone}
    </Link>
  )
}

function DetailsCard(props) {
  const { schoolInfo, searchInfo, google } = props
  const [distance, setDistance] = useState(null)
  const { t, i18n } = useTranslation()

  const mergeActivityString = (arrayOfActivites) => {
    return arrayOfActivites.map((a) => {
        const activity = a.name[i18n.language] || a.name['en']
        return activity && activity.trim()
      })
      .sort()
      .join(', ')
  }
  const googleMapLinkAddress = `https://www.google.com/maps/search/${encodeURIComponent(
    [schoolInfo.name, schoolInfo.address, schoolInfo.zip].join(' '))}`
  const academicPrograms = mergeActivityString(schoolInfo.academic_programs || [])
  const extracurricularActivities = mergeActivityString(schoolInfo.extracurricular_activities || [])
  const athleticPrograms = mergeActivityString(schoolInfo.athletic_programs || [])

  const schoolType = schoolInfoType(schoolInfo)
  const schoolTypeText = schoolType ? t(`desc_${schoolType}`) : ''

  useEffect(() => {
    const calculateDistance = () => {
      const service = new google.maps.DistanceMatrixService()
      const origin = new google.maps.LatLng(searchInfo.lat, searchInfo.lng)
      const destination = new google.maps.LatLng(
        schoolInfo.school_geometry.lat,
        schoolInfo.school_geometry.lng
      )
      service.getDistanceMatrix(
        {
          origins: [origin],
          destinations: [destination],
          travelMode: 'DRIVING',
        },
        (response, status) => {
          if (response.rows[0].elements[0].status !== 'ZERO_RESULTS') {
            const distanceInMeters = response.rows[0].elements[0].distance.value
            const distanceInMiles = distanceInMeters / 1609.344
            setDistance(distanceInMiles.toFixed(2))
          }
        }
      )
    }
    calculateDistance()
  })

  return (
    <Flex
      flexDirection="column"
      sx={{
        boxShadow: "small",
        position: "absolute",
        height: "100%",
        width: "100%"
      }}
    >
      <Grid
        flexGrow={1}
        sx={{
          overflowX: 'hidden',
          overflowY: 'scroll',
        }}
      >
        <GridSectionImage photoUrl={schoolInfo.photo_url} gridArea="Area" />

        <GridSection
          gridArea="Area-2"
          sx={{
            borderBottom: `1px solid ${Theme.colors.black20}`,
          }}
        >
          <Heading variant="h1" mb={[1, 2]}>
            {schoolInfo.name}
          </Heading>
          <Flex flexDirection="row" justifyContent="flex-start" mb={-2}>
            <Box pt=".7px" mr={1}>
              <svg
                width="15"
                height="15"
                viewBox="0 0 72 90"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="36"
                  cy="35"
                  r="26"
                  fill={Theme.colors[schoolInfo.category]}
                />
              </svg>
            </Box>
            <Text variant="thickSmall" color="black40">
              {determineSchoolGradeLevels(schoolInfo)}
              &nbsp;
            </Text>
            <Text color="black40" variant="thickSmall">
              {determineSchoolType(schoolInfo, i18n.language)}
            </Text>
          </Flex>
          {schoolInfo.notes && (
            <Flex mt={2} alignItems="center">
              <Text variant="thickSmall" color="black40">
                {t(schoolInfo.notes)}
              </Text>
            </Flex>
          )}
          <Box pt={2}>
            <Flex mt={2} alignItems="center">
              <Image minWidth="initial" src={travelIcon} mr={3} width={4} />
              <Text variant="body" mr={2} data-testid="distance">
                {searchInfo.lat ? (
                  <React.Fragment>
                    <b>{t('distance')}</b> {distance}
                    {t(' miles ')}
                    <InlineBox sx={{ alignItems: 'baseline' }}>
                      <b>{t('bus')}</b>
                      <DetailsCardLink
                        link={'https://www.tulsaschools.org/student-and-family-support/bus-routes'}
                        text={distance >= (schoolInfo.category === 'elementary' ? 1.5 : 2)
                          ? t('may_qualify')
                          : t('does_not_qualify')}
                        testId='bus routes info'
                      />
                    </InlineBox>
                  </React.Fragment>
                ) : (
                  <span>{t('enter_your_address')}</span>
                )}
              </Text>
            </Flex>

            {schoolTypeText && (
              <DetailsCardLeadInfo
                icon={schoolBuilding}
                heading={`${t('school_type')}:`}
                text={schoolTypeText} />
            )}

            {schoolInfo.bell_times && (
              <DetailsCardLeadInfo
                icon={clockIcon}
                heading={`${t('school_hours')}:`}
                text={schoolInfo.bell_times} />
            )}

            {/* School Colors */}
            {/* schoolInfo.primary_color && (
              <Flex mt={2}>
                <Image minWidth="initial" src={paintBrush} mr={3} width={4} />
                <Text variant="body" fontWeight="bold" mr={2}>
                  {t('colors')}:
                  <InlineBox fontWeight="body" ml={2}>
                    <SchoolColor color={schoolInfo.primary_color} />{' '}
                    <SchoolColor color={schoolInfo.secondary_color} />
                  </InlineBox>
                </Text>
              </Flex>
            ) */}

            {/* Current Enrollment, Student Body Size */}
            {schoolInfo.current_enrollment ? (
              <DetailsCardLeadInfo
                icon={schoolSize}
                heading={`${t('current_enrollment')}:`}
                text={`${t('students', { count: schoolInfo.current_enrollment })}`}
              />
            ) : null}

            {schoolInfo.website && (
              <DetailsCardLink link={schoolInfo.website} text={schoolInfo.website}
                icon={webAddressIcon}
                alt='web address icon'
                testId='website'
              />
            )}

            {schoolInfo.address && (
              <DetailsCardLink
                icon={addressIcon}
                link={googleMapLinkAddress}
                text={`${schoolInfo.address}, Tulsa, OK ${schoolInfo.zip}`}
                alt='physical address'
              />
            )}
            {schoolInfo.phone && (
              <Flex alignItems="center" mt={3}>
                <Image
                  minWidth="initial"
                  src={phoneIcon}
                  alt="phone number"
                  mr={3}
                  width={4}
                />
                <PhoneLink phone={schoolInfo.phone} />
              </Flex>
            )}

            {schoolInfo.has_active_powerschool_sections && ['middle', 'high'].includes(schoolInfo.category) && (
              <DetailsCardLink
                link={`https://courses.tulsaschools.org/schools/${schoolInfo.id}`}
                text={t('courses')}
                icon={courseIcon}
                alt='courses icon'
                testId='courses'
              />
            )}
          </Box>
        </GridSection>

        <GridSection gridArea={`Area-3`}>
          {academicPrograms && (
            <DetailsCardList
              heading={t('academic_programs')}
              content={academicPrograms}
              testId='academic_programs'
            />
          )}

          {extracurricularActivities && (
            <DetailsCardList
              heading={t('extracurricular_programs')}
              content={extracurricularActivities}
              testId='extracurricular_programs'
            />
          )}

          {athleticPrograms && (
            <DetailsCardList
              heading={t('athletic_programs')}
              content={athleticPrograms}
              testId='athletic_programs'
            />
          )}
        </GridSection>

        <GridSection gridArea="Area-5">
          <SchoolMap schoolInfo={schoolInfo} mt={2}></SchoolMap>
        </GridSection>
      </Grid>

      <Box
        flex={'none'}
        className="details-card-bottom"
        sx={{
          p: 4,
          backgroundSize: 'cover',
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
          backgroundColor: 'black5',
          marginTop: 'auto',
        }}
      >
        <SchoolPickerButton schoolId={schoolInfo.id} />
      </Box>
    </Flex>
  )
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
})(DetailsCard)
