import { orderBy } from "lodash";
import {
  earlySchool,
  elemSchool,
  highSchool,
  midSchool,
  school
} from "icons";

/* eslint-disable */
// TODO: what is the source of this original code?
export const pointInPolygon = (polygonPath, coordinates) => {
  let { lat, lng } = coordinates;
  let x = lat,
    y = lng;
  let inside = false;
  for (var i = 0, j = polygonPath.length - 1; i < polygonPath.length; j = i++) {
    let xi = polygonPath[i].lat,
      yi = polygonPath[i].lng;
    let xj = polygonPath[j].lat,
      yj = polygonPath[j].lng;
    let intersect =
      yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }
  return inside;
};

export const sortSchoolGeometries = res => {
  return orderBy(res, ["activity_count", "name"], ["desc", "asc"]);
};

export function selectSchoolIcon(category, isEarlyChildhood) {
  if (isEarlyChildhood) {
    return earlySchool;
  }
  switch (category) {
    case "high":
      return highSchool;
    case "middle":
    case "elementary_middle":
        return midSchool;
    case "elementary":
        return elemSchool;
    case "early_childhood":
      return earlySchool
    default:
      return school;
  }
}
