import React from "react";
import "./App.css";
import Home from "./components/app/Home";
import { Box } from "rebass";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import AllProviders from "./contexts/AllProviders";

export default function App({ waitForFonts = false }) {
  return (
    <Box className="App" minWidth={"360px"}>
      <AllProviders {...{ waitForFonts }}>
        <Box fontFamily={"body"} height={"100%"}>
          <Router>
            <Switch>
              <Redirect exact="exact" from="/home" to="/" />
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/school/:slug">
                <Home />
              </Route>
            </Switch>
          </Router>
        </Box>
      </AllProviders>
    </Box>
  );
}
