import React from 'react'
import { Heading, Flex, Box, Image } from 'rebass'
import Link from 'components/map/overlay/Link'
import { backFilledIcon } from 'icons'
import GuideToggle from 'components/map/GuideToggle'
import MyListToggle from 'components/map/MyListToggle'
import { withTranslation } from 'react-i18next'

export const SchoolInfoHeading = withTranslation()(({
  t,
  inBounds,
  resetGeometries,
  schoolGeometries,
  schoolInfo,
  searchInfo,
  sx = {},
  ...props
}) => {
  return (
    <Heading
      // number-of-results grey box
      pl={3}
      pr={1}
      py={2}
      variant="thickSmall"
      color="black60"
      sx={{
        backgroundColor: "black5",
        ...sx
      }}
      {...props}
    >
      <Flex alignItems={"center"}>
        <Box flexGrow={1}>
          {schoolInfo ? (
            <Link href="#" onClick={(event) => {event.preventDefault(); resetGeometries()}}>
              <Flex alignItems={"center"}>
                <Image src={backFilledIcon} sx={{
                  display: "block",
                  marginRight: 1
                }} />
                <Box>
                  {t("Back")}
                </Box>
              </Flex>
            </Link>
          ) : (
            (!inBounds.current && searchInfo.lat)
              ? null
              : t("showing_schools", { count: schoolGeometries.length })
          )}
        </Box>
        <Box justifyContent={"flex-end"} display={["block", "none"]}>
          <GuideToggle />
          <MyListToggle />
        </Box>
      </Flex>
    </Heading>
  )
})

export default SchoolInfoHeading
