export const gradeLevelTypes = {
  TOGGLE_ELEMENTARY: "TOGGLE_ELEMENTARY",
  TOGGLE_MIDDLE: "TOGGLE_MIDDLE",
  TOGGLE_HIGH: "TOGGLE_HIGH",
  TOGGLE_EARLY_CHILDHOOD: "TOGGLE_EARLY_CHILDHOOD",
  TOGGLE_ALL: "TOGGLE_ALL"
};

export const gradeLevelInitialToggleState = {
  removeElementary: false,
  removeMiddle: false,
  removeHigh: false,
  removeEarlyChildhood: false
};

export const gradeLevelReducer = (state = gradeLevelInitialToggleState, action) => {
  switch (action.type) {
    case gradeLevelTypes.TOGGLE_ELEMENTARY:
      return {
        ...state,
        removeElementary: !state.removeElementary
      };
    case gradeLevelTypes.TOGGLE_MIDDLE:
      return {
        ...state,
        removeMiddle: !state.removeMiddle
      };
    case gradeLevelTypes.TOGGLE_HIGH:
      return {
        ...state,
        removeHigh: !state.removeHigh
      };
    case gradeLevelTypes.TOGGLE_EARLY_CHILDHOOD:
      return {
        ...state,
        removeEarlyChildhood: !state.removeEarlyChildhood
      };
    case gradeLevelTypes.TOGGLE_ALL:
      return gradeLevelInitialToggleState;
    default:
      return state;
  }
};
