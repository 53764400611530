import React from "react";
import {Box, Text} from "rebass";
import {Theme} from "themes/Theme";

export default props => (<Box sx={{
    ".hastooltip" : {
      position: "relative",
      display: "inline-block",
      WebkitTransform: "translateZ(0)"
      /* webkit flicker fix */
    },

    // Tooltip bubble, characteristics & placement
    ".hastooltip .tooltiptext" : {
      cursor: "auto",
      visibility: "hidden",
      minWidth: "200px",
      backgroundColor: "primary",
      color: "#fff",
      textAlign: "left",
      borderRadius: "6px",
      padding: 2,
      position: "absolute",
      bottom: "120%",
      right: "0",
      marginLeft: "-50px",
      opacity: "0",
      WebkitTransform: "translateY(10px)",
      MsTransform: "translateY(10px)",
      transform: "translateY(10px)",
      WebkitTransition: "all .25s ease-out",
      transition: "all .25s ease-out"
    },

    ".hastooltip .tooltiptext a" : {
      color: "white",
      marginBottom: 2
    },

    // Creates the triangle underneath the tooltip bubble
    ".hastooltip .tooltiptext::after" : {
      content: '""',
      position: "absolute",
      top: "100%",
      right: "6%",
      marginLeft: "-5px",
      borderWidth: "10px",
      borderStyle: "solid",
      borderColor: `${Theme.colors.primary} transparent transparent transparent`
    },

    // Creates the reveal on hover
    ".hastooltip:hover .tooltiptext" : {
      visibility: "visible",
      opacity: "0.95",
      pointerEvents: "auto",
      WebkitTransform: "translateY(0px)",
      MsTransform: "translateY(0px)",
      transform: "translateY(0px)"
    }
  }}>
  {/* The question mark bubble in main UI */}
  <div className="hastooltip">
    <svg width="50" height="50" viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M107 54C107 83.2711 83.2711 107 54 107C24.7289 107 1 83.2711 1 54C1 24.7289 24.7289 1 54 1C83.2711 1 107 24.7289 107 54Z" fill={Theme.colors.primary} stroke="white" strokeWidth="2"/>
      <path d="M47.1507 64.5104H59.1123V63.1898C59.1123 58.815 61.013 56.6139 64.6877 54.2751C69.6295 51.1385 73 47.204 73 40.518C73 30.1727 64.9664 25 54.1452 25C44.287 25 36.1014 30.0626 36 42.1689H48.9753C48.9753 39.0047 51.3068 36.7761 54.0438 36.7761C56.5781 36.7761 58.6055 38.6471 58.6055 41.3985C58.6055 44.4801 56.1473 46.3786 52.9288 48.6622C49.1527 51.3311 47.1507 54.1376 47.1507 63.1898V64.5104ZM53.3342 83C57.0596 83 60.3795 79.5882 60.4301 75.296C60.3795 71.1139 57.0596 67.7021 53.3342 67.7021C49.4062 67.7021 46.1877 71.1139 46.2384 75.296C46.1877 79.5882 49.4062 83 53.3342 83Z" fill="white"/>
    </svg>
    <span className="tooltiptext">
      <Text variant="small">
        {props.children}
      </Text>
    </span>
  </div>
</Box>);
