import { Box } from "rebass";
import React from "react";
import Toggle from "./Toggle";
import { changeLanguage } from "utils/language-helpers";


const handleChangeLanguage = () => {
  changeLanguage();
};

const LanguageToggle = props => (<Box {...props} sx={{
  color: 'white',
  textAlign: "center",
  display: "flex",
  alignItems: "center"
}}>
  <Toggle style={{
    display: "inline-block"
  }} variant="clear" onClick={() => handleChangeLanguage()}></Toggle>
</Box>);

export default LanguageToggle
