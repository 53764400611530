import React from 'react'
import { Flex, Image, Text } from 'rebass'

function DetailsCardLeadInfo({ icon, heading, text}) {
  return (
    <Flex mt={3} alignItems="center">
      <Image minWidth="initial" src={icon} mr={3} width={4} />
      <Text variant="body" mr={2}>
        <b>{heading}</b>{' '}
        {text}
      </Text>
    </Flex>
  )
}

export default DetailsCardLeadInfo
