import React from 'react'
import { Box } from 'rebass'
import { Theme } from 'themes/Theme'

export const Window = ({children, sx = {}, ...props}) => {
  return (
    <Box sx={{
      backgroundColor: Theme.colors.white,
      boxShadow: Theme.shadows.window,
      m: [undefined, 1],
      height: ["100%", "auto"],
      width: "100%",
      position: ["absolute", "relative"],
      ...sx
    }} {...props}>
      {children}
    </Box>
  )
}

export default Window
