import React, { useEffect, useState } from "react";
import {ThemeProvider as EmotionThemeProvider} from "emotion-theming";
import WebFont from "webfontloader";
import {Theme} from "./Theme";
// order of style application: 1. variant, 2. sx prop, 3. css prop, 4. styled system props
import {IconContext} from "react-icons";

const ThemeProvider = ({waitForFonts = false, children}) => {
  const [fontsLoaded, setFontsLoaded] = useState(!waitForFonts);

  useEffect(() => {
    WebFont.load({
      custom: {
        families: ["Inter"],
        urls: [`${process.env.PUBLIC_URL}/fonts/inter/inter.css`]
      },
      active: () => {
        setFontsLoaded(true);
      }
    });
  }, []);

  return fontsLoaded && (
    <EmotionThemeProvider theme={Theme}>
      <IconContext.Provider value={{
          color: "white",
          className: "react-icon"
        }}>
        {children}
      </IconContext.Provider>
    </EmotionThemeProvider>
  )
};

export default ThemeProvider
