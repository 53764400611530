import React from "react";
import { Box } from "rebass";

export const MyListNumber = ({number}) => {
  return (
    <Box sx={{
      borderRadius: "50%",
      width: "26px",
      height: "26px",
      padding: "8px",
      backgroundColor: "black5",
      color: "#666666",
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: 0.6
    }}>
      {number}
    </Box>
  );
};

export default MyListNumber;
