import i18n from "i18n";

let languageSettings = i18n;

export const changeLanguage = () => {
  return languageSettings.changeLanguage(
    languageSettings.language === "en" ? "es" : "en"
  );
};

export function titleize(sentence) {
  if (!sentence || !sentence.split) return sentence;

  const _titleizeWord = string =>
    string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

  sentence = sentence.replace(/_/g, " ");
  sentence = sentence.replace("/", " / ");
  //This if statement is for the SearchResult determineSchoolGradeLevels
  if (sentence.includes("-")) {
    sentence = sentence.split("-").map(word => _titleizeWord(word));
    return sentence.join("-");
  }
  const result = sentence.split(" ").map(word => _titleizeWord(word));

  return result.join(" ");
}
