import React from 'react'
import { Box } from 'rebass'
import { Theme } from 'themes/Theme'

export const WindowHeader = ({children, sx = {}, ...props}) => {
  return (
    <Box sx={{
      backgroundColor: Theme.colors.black5,
      p: 3,
      ...sx
    }} {...props}>
      {children}
    </Box>
  )
}

export default WindowHeader
