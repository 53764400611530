import React from 'react'
import { withTranslation } from 'react-i18next'
import { Box, Button } from 'rebass'
import { IoIosClose } from 'react-icons/io'

export const ClearFiltersButton = withTranslation()(({t, sx = {}, ...props}) => {
  return (
    <Button
      ml={1}
      data-testid="clear-filters-button"
      sx={{
        backgroundColor: "white10",
        color: "white",
        flexShrink: "0",
        fontWeight: "semiBold",
        cursor: "pointer",
        ":hover" : {
          backgroundColor: "primary120"
        },
        display: "flex",
        alignItems: "center",
        ...sx
      }}
      {...props}
    >
      <Box mr={[0, 1, 0, 1]} ml={[0, -2, 0, -2]} sx={{
        display: "flex",
        alignItems: "center"
      }}>
        <IoIosClose style={{ height: "1.5em", width: "1.5em" }}/>
      </Box>

      <Box sx={{ display: ['none', 'inherit', 'none', 'inherit'] }}>
        {t("clear_filters")}
      </Box>
    </Button>
  )
})

export default ClearFiltersButton
