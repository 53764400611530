import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./translations/en/common.json";
import translationES from "./translations/es/common.json";
import guideWindowES from "./translations/es/guide-window.json";
import myListWindowES from "./translations/es/my-list-window.json";

const resources = {
  en: {
    translation: translationEN
  },
  es: {
    translation: {
      ...translationES,
      ...guideWindowES,
      ...myListWindowES,
    }
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  keySeparator: false, // we do not use keys in form messages.welcome
  nsSeparator: false,
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  }
});

export default i18n;
