import React from 'react'
import { Flex, Heading, Text } from 'rebass'

function DetailsCardList({ heading, content, testId }) {
  return (
    <React.Fragment>
      <Heading variant="h2" mb={2}>
        {heading}
      </Heading>
      <Flex mb={4} alignItems="center" flexWrap="wrap">
        <Text data-testid={testId} variant="body">
          {content}
        </Text>
      </Flex>
    </React.Fragment>
  )
}

export default DetailsCardList
